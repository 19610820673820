import { NgKLoggerService } from '@kin/ngk-logger';
import { LegacyApiPagesComponent } from 'app/common/services/legacy/quote-pages';

const LEGACY_PAGES_API_CO_APPLICANT_INFO_COMPONENT_ATTRIBUTE_NAMES = ['coapplicant_first_name', 'coapplicant_last_name', 'coapplicant_dob', 'include_coapplicant'];

export function getComponentsForLegacyPagesApiCoApplicantInfo(components: LegacyApiPagesComponent[], logger: NgKLoggerService): undefined {
  const coApplicantInfoComponents = components.filter((component) => LEGACY_PAGES_API_CO_APPLICANT_INFO_COMPONENT_ATTRIBUTE_NAMES.includes(component.attribute_name));
  if (coApplicantInfoComponents.length === 0 || coApplicantInfoComponents.length !== LEGACY_PAGES_API_CO_APPLICANT_INFO_COMPONENT_ATTRIBUTE_NAMES.length) {
    logger.error({ message: 'could not find components for co-applicant-info page', context: 'getComponentsForLegacyPagesApiCoApplicantInfo', priority: 'P3' });
  }
}
