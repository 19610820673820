/**
 * This class provides a skeletal implementation of the ISegmentsCacheSync interface
 * to minimize the effort required to implement this interface.
 */
var AbstractSegmentsCacheSync = /** @class */function () {
  function AbstractSegmentsCacheSync() {}
  /**
   * For server-side synchronizer: add the given list of segments to the cache, with an empty list of keys. The segments that already exist are not modified.
   * For client-side synchronizer: the method is not used.
   */
  AbstractSegmentsCacheSync.prototype.registerSegments = function (names) {
    return false;
  };
  /**
   * For server-side synchronizer: set the change number of `name` segment.
   * For client-side synchronizer: the method is not used.
   */
  AbstractSegmentsCacheSync.prototype.setChangeNumber = function (name, changeNumber) {
    return true;
  };
  /**
   * For server-side synchronizer: get the change number of `name` segment.
   * For client-side synchronizer: the method is not used.
   */
  AbstractSegmentsCacheSync.prototype.getChangeNumber = function (name) {
    return -1;
  };
  /**
   * For server-side synchronizer: the method is not used.
   * For client-side synchronizer: reset the cache with the given list of segments.
   */
  AbstractSegmentsCacheSync.prototype.resetSegments = function (names) {
    return true;
  };
  return AbstractSegmentsCacheSync;
}();
export { AbstractSegmentsCacheSync };