import { timeout } from '../../../utils/promise/timeout';
import { SDK_SEGMENTS_ARRIVED } from '../../../readiness/constants';
import { SYNC_MYSEGMENTS_FETCH_RETRY } from '../../../logger/constants';
/**
 * factory of MySegments updater, a task that:
 *  - fetches mySegments using `mySegmentsFetcher`
 *  - updates `mySegmentsCache`
 *  - uses `segmentsEventEmitter` to emit events related to segments data updates
 */
export function mySegmentsUpdaterFactory(log, mySegmentsFetcher, splitsCache, mySegmentsCache, segmentsEventEmitter, requestTimeoutBeforeReady, retriesOnFailureBeforeReady, matchingKey) {
  var readyOnAlreadyExistentState = true;
  var startingUp = true;
  /** timeout and telemetry decorator for `splitChangesFetcher` promise  */
  function _promiseDecorator(promise) {
    if (startingUp) promise = timeout(requestTimeoutBeforeReady, promise);
    return promise;
  }
  // @TODO if allowing pluggable storages, handle async execution
  function updateSegments(segmentsData) {
    var shouldNotifyUpdate;
    if (Array.isArray(segmentsData)) {
      // Update the list of segment names available
      shouldNotifyUpdate = mySegmentsCache.resetSegments(segmentsData);
    } else {
      // Add/Delete the segment
      var name_1 = segmentsData.name,
        add = segmentsData.add;
      if (mySegmentsCache.isInSegment(name_1) !== add) {
        shouldNotifyUpdate = true;
        if (add) mySegmentsCache.addToSegment(name_1);else mySegmentsCache.removeFromSegment(name_1);
      } else {
        shouldNotifyUpdate = false;
      }
    }
    // Notify update if required
    if (splitsCache.usesSegments() && (shouldNotifyUpdate || readyOnAlreadyExistentState)) {
      readyOnAlreadyExistentState = false;
      segmentsEventEmitter.emit(SDK_SEGMENTS_ARRIVED);
    }
  }
  function _mySegmentsUpdater(retry, segmentsData, noCache) {
    var updaterPromise = segmentsData ?
    // If segmentsData is provided, there is no need to fetch mySegments
    new Promise(function (res) {
      updateSegments(segmentsData);
      res(true);
    }) :
    // If not provided, fetch mySegments
    mySegmentsFetcher(matchingKey, noCache, _promiseDecorator).then(function (segments) {
      // Only when we have downloaded segments completely, we should not keep retrying anymore
      startingUp = false;
      updateSegments(segments);
      return true;
    });
    return updaterPromise.catch(function (error) {
      if (startingUp && retriesOnFailureBeforeReady > retry) {
        retry += 1;
        log.warn(SYNC_MYSEGMENTS_FETCH_RETRY, [retry, error]);
        return _mySegmentsUpdater(retry); // no need to forward `segmentList` and `noCache` params
      } else {
        startingUp = false;
      }
      return false;
    });
  }
  /**
   * MySegments updater returns a promise that resolves with a `false` boolean value if it fails to fetch mySegments or synchronize them with the storage.
   * Returned promise will not be rejected.
   *
   * @param {SegmentsData | undefined} segmentsData it can be:
   *  (1) the list of mySegments names to sync in the storage,
   *  (2) an object with a segment name and action (true: add, or false: delete) to update the storage,
   *  (3) or `undefined`, for which the updater will fetch mySegments in order to sync the storage.
   * @param {boolean | undefined} noCache true to revalidate data to fetch
   */
  return function mySegmentsUpdater(segmentsData, noCache) {
    return _mySegmentsUpdater(0, segmentsData, noCache);
  };
}