import { isString, isFiniteNumber, isBoolean } from '../../utils/lang';
export function validateAttribute(log, attributeKey, attributeValue, method) {
  if (!isString(attributeKey) || attributeKey.length === 0) {
    log.warn(method + ": you passed an invalid attribute name, attribute name must be a non-empty string.");
    return false;
  }
  var isStringVal = isString(attributeValue);
  var isFiniteVal = isFiniteNumber(attributeValue);
  var isBoolVal = isBoolean(attributeValue);
  var isArrayVal = Array.isArray(attributeValue);
  if (!(isStringVal || isFiniteVal || isBoolVal || isArrayVal)) {
    // If it's not of valid type.
    log.warn(method + ": you passed an invalid attribute value for " + attributeKey + ". Acceptable types are: string, number, boolean and array of strings.");
    return false;
  }
  return true;
}