import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import { PHONE_NUMBERS } from 'app/common/constants/contact-constants';
import { PhonePipe } from 'app/common/pipes/phone-number/phone.pipe';
import '@kin/web-components/dist/external/kin-alert/kin-alert';

@Component({
  selector: 'app-locked-quote-alert',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PhonePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
    <kin-alert [open]="isOpenSignal()" variant="warning" data-testid="locked-quote-alert">
      <p id="readonly-help">
        Your responses have been recorded to ensure an accurate quote. To make changes, please call us at <a href="tel:{{ phoneNumber | phone }}" class="link">(855) 717-0022</a>.
      </p>
    </kin-alert>
  `,
})
export class LockedQuoteAlertComponent {
  isOpenSignal = input<boolean>(true, { alias: 'open' });
  phoneNumber = PHONE_NUMBERS.sales;
}
