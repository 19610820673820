var ImpressionsCacheInMemory = /** @class */function () {
  /**
   *
   * @param impressionsQueueSize number of queued impressions to call onFullQueueCb.
   * Default value is 0, that means no maximum value, in case we want to avoid this being triggered.
   */
  function ImpressionsCacheInMemory(impressionsQueueSize) {
    if (impressionsQueueSize === void 0) {
      impressionsQueueSize = 0;
    }
    this.maxQueue = impressionsQueueSize;
    this.queue = [];
  }
  ImpressionsCacheInMemory.prototype.setOnFullQueueCb = function (cb) {
    this.onFullQueue = cb;
  };
  /**
   * Store impressions in sequential order
   */
  ImpressionsCacheInMemory.prototype.track = function (data) {
    var _a;
    (_a = this.queue).push.apply(_a, data);
    // Check if the cache queue is full and we need to flush it.
    if (this.maxQueue > 0 && this.queue.length >= this.maxQueue && this.onFullQueue) {
      this.onFullQueue();
    }
  };
  /**
   * Clear the data stored on the cache.
   */
  ImpressionsCacheInMemory.prototype.clear = function () {
    this.queue = [];
  };
  /**
   * Pop the collected data, used as payload for posting.
   */
  ImpressionsCacheInMemory.prototype.pop = function (toMerge) {
    var data = this.queue;
    this.clear();
    return toMerge ? toMerge.concat(data) : data;
  };
  /**
   * Check if the cache is empty.
   */
  ImpressionsCacheInMemory.prototype.isEmpty = function () {
    return this.queue.length === 0;
  };
  return ImpressionsCacheInMemory;
}();
export { ImpressionsCacheInMemory };