import { __extends } from "tslib";
import { startsWith } from '../utils/lang';
import { KeyBuilder } from './KeyBuilder';
var KeyBuilderCS = /** @class */function (_super) {
  __extends(KeyBuilderCS, _super);
  function KeyBuilderCS(prefix, matchingKey) {
    var _this = _super.call(this, prefix) || this;
    _this.matchingKey = matchingKey;
    _this.regexSplitsCacheKey = new RegExp("^" + prefix + "\\.(splits?|trafficType|flagSet)\\.");
    return _this;
  }
  /**
   * @override
   */
  KeyBuilderCS.prototype.buildSegmentNameKey = function (segmentName) {
    return this.prefix + "." + this.matchingKey + ".segment." + segmentName;
  };
  KeyBuilderCS.prototype.extractSegmentName = function (builtSegmentKeyName) {
    var prefix = this.prefix + "." + this.matchingKey + ".segment.";
    if (startsWith(builtSegmentKeyName, prefix)) return builtSegmentKeyName.substr(prefix.length);
  };
  // @BREAKING: The key used to start with the matching key instead of the prefix, this was changed on version 10.17.3
  KeyBuilderCS.prototype.buildOldSegmentNameKey = function (segmentName) {
    return this.matchingKey + "." + this.prefix + ".segment." + segmentName;
  };
  // @BREAKING: The key used to start with the matching key instead of the prefix, this was changed on version 10.17.3
  KeyBuilderCS.prototype.extractOldSegmentKey = function (builtSegmentKeyName) {
    var prefix = this.matchingKey + "." + this.prefix + ".segment.";
    if (startsWith(builtSegmentKeyName, prefix)) return builtSegmentKeyName.substr(prefix.length);
  };
  KeyBuilderCS.prototype.buildLastUpdatedKey = function () {
    return this.prefix + ".splits.lastUpdated";
  };
  KeyBuilderCS.prototype.isSplitsCacheKey = function (key) {
    return this.regexSplitsCacheKey.test(key);
  };
  return KeyBuilderCS;
}(KeyBuilder);
export { KeyBuilderCS };