import { objectAssign } from '../utils/lang/objectAssign';
import { _Set } from '../utils/lang/sets';
var FORBIDDEN_HEADERS = new _Set(['splitsdkclientkey', 'splitsdkversion', 'splitsdkmachineip', 'splitsdkmachinename', 'splitsdkimpressionsmode', 'host', 'referrer', 'content-type', 'content-length', 'content-encoding', 'accept', 'keep-alive', 'x-fastly-debug']);
export function decorateHeaders(settings, headers) {
  var _a;
  if ((_a = settings.sync.requestOptions) === null || _a === void 0 ? void 0 : _a.getHeaderOverrides) {
    try {
      var headerOverrides_1 = settings.sync.requestOptions.getHeaderOverrides({
        headers: objectAssign({}, headers)
      });
      Object.keys(headerOverrides_1).filter(function (key) {
        return !FORBIDDEN_HEADERS.has(key.toLowerCase());
      }).forEach(function (key) {
        return headers[key] = headerOverrides_1[key];
      });
    } catch (e) {
      settings.log.error('Problem adding custom headers to request decorator: ' + e);
    }
  }
  return headers;
}