import { isObject } from '../lang';
import { validateAttribute } from './attribute';
import { ERROR_NOT_PLAIN_OBJECT } from '../../logger/constants';
export function validateAttributes(log, maybeAttrs, method) {
  // Attributes are optional
  if (maybeAttrs == undefined || isObject(maybeAttrs))
    // eslint-disable-line eqeqeq
    return maybeAttrs;
  log.error(ERROR_NOT_PLAIN_OBJECT, [method, 'attributes']);
  return false;
}
export function validateAttributesDeep(log, maybeAttributes, method) {
  if (!validateAttributes(log, maybeAttributes, method)) return false;
  var result = true;
  Object.keys(maybeAttributes).forEach(function (attributeKey) {
    if (!validateAttribute(log, attributeKey, maybeAttributes[attributeKey], method)) result = false;
  });
  return result;
}