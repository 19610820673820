import { settingsValidation } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation';
import { validateRuntime } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/runtime';
import { validateLogger } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/logger/builtinLogger';
import { LocalhostFromObject } from '@splitsoftware/splitio-commons/esm/sync/offline/LocalhostFromObject';
import { validateConsent } from '@splitsoftware/splitio-commons/esm/utils/settingsValidation/consent';
import { defaults } from './defaults/browser';
import { validateStorage } from './storage/browser';
import { validateIntegrations } from './integrations/browser';
var params = {
  defaults: defaults,
  acceptKey: true,
  acceptTT: true,
  runtime: validateRuntime,
  storage: validateStorage,
  integrations: validateIntegrations,
  logger: validateLogger,
  localhost: function () {
    return LocalhostFromObject();
  },
  consent: validateConsent
};
export function settingsFactory(config) {
  return settingsValidation(config, params);
}