import { findIndex } from '../../utils/lang';
import { thenable } from '../../utils/promise/thenable';
import { ENGINE_COMBINER_AND } from '../../logger/constants';
export function andCombinerContext(log, matchers) {
  function andResults(results) {
    // Array.prototype.every is supported by target environments
    var hasMatchedAll = results.every(function (value) {
      return value;
    });
    log.debug(ENGINE_COMBINER_AND, [hasMatchedAll]);
    return hasMatchedAll;
  }
  return function andCombiner() {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      params[_i] = arguments[_i];
    }
    var matcherResults = matchers.map(function (matcher) {
      return matcher.apply(void 0, params);
    });
    // If any matching result is a thenable we should use Promise.all
    if (findIndex(matcherResults, thenable) !== -1) {
      return Promise.all(matcherResults).then(andResults);
    } else {
      return andResults(matcherResults);
    }
  };
}