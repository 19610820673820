import { AfterContentInit, Directive, ElementRef, HostBinding, HostListener, Input, signal } from '@angular/core';

/**
 * Adds support for readonly to radio groups
 *
 * @example
 * <select appReadonlySelect>
 * 		<option>...</option>
 * </select>
 */
@Directive({
  selector: 'select[appReadonlySelect]',
  standalone: true,
})
export class ReadonlySelectDirective implements AfterContentInit {
  private readonly = signal(false);

  @Input() set appReadonlySelect(value: boolean) {
    this.readonly.set(value);
  }

  @HostBinding('attr.aria-disabled') get ariaDisabled() {
    return this.readonly() ? 'true' : 'false';
  }

  @HostBinding('attr.aria-labelledby') get ariaLabelledBy() {
    return this.readonly() ? 'readonly-help' : null;
  }

  constructor(private el: ElementRef) {}

  ngAfterContentInit() {
    this.readonly.set(this.readonly());
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.readonly()) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (this.readonly() && event.key === ' ') {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
