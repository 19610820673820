<form [formGroup]="coApplicantInfoForm" class="qui-form-layout__col-center flex flex-col gap-400" (submit)="handleSubmit($event)" id="quote-form">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card title="Do you want to add a co-applicant?" icon="lucideGoal">
    <ng-template #subcopyTemplate>
      <p class="mb-300">If there's another person's name on your home's deed or someone else with an insurable interest in the property, you must add them as a co-applicant.</p>
      <p>If the co-applicant is not a person (like a Trust, LLC, etc.) please call us to add them to your policy.</p>
    </ng-template>

    <div class="grid grid-cols-1 gap-x-400 gap-y-400 sm:grid-cols-6 md:gap-y-600">
      <app-text-input
        class="sm:col-span-3"
        [control]="coApplicantInfoForm.controls.firstName"
        label="Co-applicant first name"
        id="first-name"
        data-testid="co-applicant-first-name"
        [required]="true"
        [errorMessages]="errorMessages.firstName"
        helpText="Please provide their legal name"
        [readonly]="isFieldLocked('firstName')"
      ></app-text-input>

      <app-text-input
        class="sm:col-span-3"
        [control]="coApplicantInfoForm.controls.lastName"
        label="Co-applicant last name"
        id="last-name"
        data-testid="co-applicant-last-name"
        [required]="true"
        [errorMessages]="errorMessages.lastName"
        helpText="Please provide their legal name"
        [readonly]="isFieldLocked('lastName')"
      ></app-text-input>

      <app-text-input
        class="sm:col-span-3"
        [control]="coApplicantInfoForm.controls.dob"
        label="Co-applicant date of birth"
        [maskito]="maskitoDateOptions"
        inputmode="numeric"
        placeholder="MM/DD/YYYY"
        id="dob"
        data-testid="co-applicant-dob"
        autocomplete="birthday"
        type="text"
        [required]="true"
        [errorMessages]="errorMessages.dob"
        [readonly]="isFieldLocked('dob')"
      ></app-text-input>
    </div>
  </app-form-card>
</form>
