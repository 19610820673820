import { syncTaskFactory } from '../syncTask';
import { SUBMITTERS_PUSH, SUBMITTERS_PUSH_FAILS, SUBMITTERS_PUSH_RETRY } from '../../logger/constants';
/**
 * Base function to create submitters, such as ImpressionsSubmitter and EventsSubmitter
 */
export function submitterFactory(log, postClient, sourceCache, postRate, dataName, fromCacheToPayload, maxRetries, debugLogs // true for telemetry submitters
) {
  if (maxRetries === void 0) {
    maxRetries = 0;
  }
  var retries = 0;
  var data;
  function postData() {
    if (sourceCache.isEmpty() && !data) return Promise.resolve();
    data = sourceCache.pop(data);
    var dataCountMessage = typeof data.length === 'number' ? data.length + " " + dataName : dataName;
    log[debugLogs ? 'debug' : 'info'](SUBMITTERS_PUSH, [dataCountMessage]);
    var jsonPayload = JSON.stringify(fromCacheToPayload ? fromCacheToPayload(data) : data);
    if (!maxRetries) data = undefined;
    return postClient(jsonPayload).then(function () {
      retries = 0;
      data = undefined;
    }).catch(function (err) {
      if (!maxRetries) {
        log[debugLogs ? 'debug' : 'warn'](SUBMITTERS_PUSH_FAILS, [dataCountMessage, err]);
      } else if (retries === maxRetries) {
        retries = 0;
        data = undefined;
        log[debugLogs ? 'debug' : 'warn'](SUBMITTERS_PUSH_FAILS, [dataCountMessage, err]);
      } else {
        retries++;
        log[debugLogs ? 'debug' : 'warn'](SUBMITTERS_PUSH_RETRY, [dataCountMessage, err]);
      }
    });
  }
  return syncTaskFactory(log, postData, postRate, dataName + ' submitter');
}
/**
 * Decorates a provided submitter with a first execution window
 */
export function firstPushWindowDecorator(submitter, firstPushWindow) {
  var running = false;
  var stopEventPublisherTimeout;
  var originalStart = submitter.start;
  submitter.start = function () {
    running = true;
    stopEventPublisherTimeout = setTimeout(originalStart, firstPushWindow);
  };
  var originalStop = submitter.stop;
  submitter.stop = function () {
    running = false;
    clearTimeout(stopEventPublisherTimeout);
    originalStop();
  };
  submitter.isRunning = function () {
    return running;
  };
  return submitter;
}