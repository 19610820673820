import { computed } from '@angular/core';
import { signalStoreFeature, withComputed, withState, withMethods } from '@ngrx/signals';

export interface SubmittedFieldsState {
  [formKey: string]: {
    [fieldName: string]: boolean;
  };
}

export type LockableState = { submittedFields: SubmittedFieldsState };

export function withLockable() {
  return signalStoreFeature(
    withState<LockableState>({ submittedFields: {} }),
    withComputed(({ submittedFields }) => ({
      submittedFields: computed(() => submittedFields()),
    })),
    withMethods(({ submittedFields }) => ({
      isFieldSubmitted(fieldName: string, formKey: string): boolean {
        return submittedFields()[formKey]?.[fieldName] ?? false;
      },
    })),
  );
}
