import { submitterFactory } from './submitter';
/**
 * Converts `impressionCounts` data from cache into request payload.
 */
export function fromImpressionCountsCollector(impressionsCount) {
  var pf = [];
  var keys = Object.keys(impressionsCount);
  for (var i = 0; i < keys.length; i++) {
    var splitted = keys[i].split('::');
    if (splitted.length !== 2) continue;
    var featureName = splitted[0];
    var timeFrame = splitted[1];
    var impressionsInTimeframe = {
      f: featureName,
      m: Number(timeFrame),
      rc: impressionsCount[keys[i]] // Count
    };
    pf.push(impressionsInTimeframe);
  }
  return {
    pf: pf
  };
}
var IMPRESSIONS_COUNT_RATE = 1800000; // 30 minutes
/**
 * Submitter that periodically posts impression counts
 */
export function impressionCountsSubmitterFactory(params) {
  var log = params.settings.log,
    postTestImpressionsCount = params.splitApi.postTestImpressionsCount,
    impressionCounts = params.storage.impressionCounts;
  if (impressionCounts) {
    // retry impressions counts only once.
    return submitterFactory(log, postTestImpressionsCount, impressionCounts, IMPRESSIONS_COUNT_RATE, 'impression counts', fromImpressionCountsCollector, 1);
  }
}