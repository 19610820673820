import { ChangeDetectionStrategy, Component, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideHouse, lucideCircleAlert } from '@ng-icons/lucide';
import { Lockable, WithLockableFields, LockedQuoteAlertComponent } from 'app/create-quote/form-locking';
import { RentalStatusQuoteFormInput } from './rental-status.types';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { FormCardComponent } from '../shared/form-card.component';
import { ReadonlyRadioDirective } from '../../../common/directives/readonly-radio.directive';

@Component({
  selector: 'app-rental-status',
  standalone: true,
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent, ReadonlyRadioDirective, LockedQuoteAlertComponent],
  templateUrl: './rental-status.component.html',
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/rental_status',
})
export class QuoteFormRentalStatusComponent extends BaseQuoteForm<RentalStatusQuoteFormInput> implements OnInit, Lockable {
  public formDataIn: RentalStatusQuoteFormInput = this.quoteDraftStore.rentalStatusQuoteFormSelector();

  public formDefinition = {
    rentalStatus: new FormControl<boolean | undefined>(undefined, Validators.required),
  };
  public rentalStatusForm = new FormGroup(this.formDefinition);

  public get showError(): boolean {
    const control = this.rentalStatusForm.get('rentalStatus');
    return !!control?.invalid && !!control?.touched;
  }

  public ngOnInit() {
    if (this.formDataIn) {
      this.rentalStatusForm.controls.rentalStatus.setValue(this.formDataIn.rentalStatus);
    }
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  getFormControls() {
    return this.rentalStatusForm.controls;
  }

  private getFormValues(): Pick<QuoteDraftState, 'rentalStatus'> {
    const { controls } = this.rentalStatusForm;
    const formValues = {
      rentalStatus: controls.rentalStatus.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.rentalStatusForm.markAllAsTouched();
    if (this.rentalStatusForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }

  public showFormRequiredError(): boolean {
    const control = this.rentalStatusForm.get('rentalStatus');
    return control ? control.invalid && control.touched : false;
  }
}
