import { isString } from '../utils/lang';
var NUMERIC_IDENTIFIER_REGEX = /^[0-9]+$/;
var METADATA_DELIMITER = '+';
var PRERELEASE_DELIMITER = '-';
var VALUE_DELIMITER = '.';
/**
 * Compares two strings. If both strings are numeric identifiers, they are compared numerically. Otherwise, they are compared lexicographically.
 * This could be implemented using `a.localeCompare(b, undefined, { numeric: true })` but locale options are not broadly supported.
 */
function compareStrings(a, b) {
  if (NUMERIC_IDENTIFIER_REGEX.test(a) && NUMERIC_IDENTIFIER_REGEX.test(b)) {
    var result = a.length - b.length;
    if (result !== 0) {
      return result;
    }
  }
  return a < b ? -1 : a > b ? 1 : 0;
}
// Sanitizes a numeric identifier by removing leading zeros
function sanitizeNumericIdentifier(value) {
  return value.replace(/^0+(?=\d)/, '');
}
function throwError(version) {
  throw new Error('Unable to convert to Semver, incorrect format: ' + version);
}
var Semver = /** @class */function () {
  function Semver(version) {
    if (!isString(version)) throwError(version);
    // Separate metadata if exists
    var index = version.indexOf(METADATA_DELIMITER);
    var _a = index === -1 ? [version] : [version.slice(0, index), version.slice(index + 1)],
      vWithoutMetadata = _a[0],
      metadata = _a[1];
    if (metadata === '') throwError(version);
    // Set pre-release versions if exists
    index = vWithoutMetadata.indexOf(PRERELEASE_DELIMITER);
    if (index === -1) {
      this._isStable = true;
      this._preRelease = [];
    } else {
      this._isStable = false;
      this._preRelease = vWithoutMetadata.slice(index + 1).split(VALUE_DELIMITER).map(function (value) {
        if (!value) throwError(version);
        return NUMERIC_IDENTIFIER_REGEX.test(value) ? sanitizeNumericIdentifier(value) : value;
      });
      vWithoutMetadata = vWithoutMetadata.slice(0, index);
    }
    // Set major, minor, and patch versions
    var vParts = vWithoutMetadata.split(VALUE_DELIMITER).map(function (value) {
      if (!value || !NUMERIC_IDENTIFIER_REGEX.test(value)) throwError(version);
      return sanitizeNumericIdentifier(value);
    });
    if (vParts.length !== 3) throwError(version);
    this._major = vParts[0];
    this._minor = vParts[1];
    this._patch = vParts[2];
    // Set version string
    this.version = vParts.join(VALUE_DELIMITER);
    if (this._preRelease.length) this.version += PRERELEASE_DELIMITER + this._preRelease.join(VALUE_DELIMITER);
    if (metadata) this.version += METADATA_DELIMITER + metadata;
  }
  /**
   * Precedence comparision between 2 Semver objects.
   *
   * @return `0` if `this === toCompare`, `-1` if `this < toCompare`, and `1` if `this > toCompare`
   */
  Semver.prototype.compare = function (toCompare) {
    if (this.version === toCompare.version) return 0;
    var result = compareStrings(this._major, toCompare._major);
    if (result !== 0) return result;
    result = compareStrings(this._minor, toCompare._minor);
    if (result !== 0) return result;
    result = compareStrings(this._patch, toCompare._patch);
    if (result !== 0) return result;
    if (!this._isStable && toCompare._isStable) return -1;
    if (this._isStable && !toCompare._isStable) return 1;
    for (var i = 0, length_1 = Math.min(this._preRelease.length, toCompare._preRelease.length); i < length_1; i++) {
      var result_1 = compareStrings(this._preRelease[i], toCompare._preRelease[i]);
      if (result_1 !== 0) return result_1;
    }
    return this._preRelease.length - toCompare._preRelease.length;
  };
  return Semver;
}();
export { Semver };