import { setToArray, _Set } from '../../utils/lang/sets';
import { DEFAULT_CACHE_SIZE } from '../inRedis/constants';
var UniqueKeysCacheInMemoryCS = /** @class */function () {
  /**
   *
   * @param impressionsQueueSize number of queued impressions to call onFullQueueCb.
   * Default value is 0, that means no maximum value, in case we want to avoid this being triggered.
   */
  function UniqueKeysCacheInMemoryCS(uniqueKeysQueueSize) {
    if (uniqueKeysQueueSize === void 0) {
      uniqueKeysQueueSize = DEFAULT_CACHE_SIZE;
    }
    this.uniqueTrackerSize = 0;
    this.uniqueKeysTracker = {};
    this.maxStorage = uniqueKeysQueueSize;
  }
  UniqueKeysCacheInMemoryCS.prototype.setOnFullQueueCb = function (cb) {
    this.onFullQueue = cb;
  };
  /**
   * Store unique keys per feature.
   */
  UniqueKeysCacheInMemoryCS.prototype.track = function (userKey, featureName) {
    if (!this.uniqueKeysTracker[userKey]) this.uniqueKeysTracker[userKey] = new _Set();
    var tracker = this.uniqueKeysTracker[userKey];
    if (!tracker.has(featureName)) {
      tracker.add(featureName);
      this.uniqueTrackerSize++;
    }
    if (this.uniqueTrackerSize >= this.maxStorage && this.onFullQueue) {
      this.onFullQueue();
    }
  };
  /**
   * Clear the data stored on the cache.
   */
  UniqueKeysCacheInMemoryCS.prototype.clear = function () {
    this.uniqueTrackerSize = 0;
    this.uniqueKeysTracker = {};
  };
  /**
   * Pop the collected data, used as payload for posting.
   */
  UniqueKeysCacheInMemoryCS.prototype.pop = function () {
    var data = this.uniqueKeysTracker;
    this.clear();
    return this.fromUniqueKeysCollector(data);
  };
  /**
   * Check if the cache is empty.
   */
  UniqueKeysCacheInMemoryCS.prototype.isEmpty = function () {
    return Object.keys(this.uniqueKeysTracker).length === 0;
  };
  /**
   * Converts `uniqueKeys` data from cache into request payload.
   */
  UniqueKeysCacheInMemoryCS.prototype.fromUniqueKeysCollector = function (uniqueKeys) {
    var payload = [];
    var userKeys = Object.keys(uniqueKeys);
    for (var k = 0; k < userKeys.length; k++) {
      var userKey = userKeys[k];
      var featureNames = setToArray(uniqueKeys[userKey]);
      var uniqueKeysPayload = {
        k: userKey,
        fs: featureNames
      };
      payload.push(uniqueKeysPayload);
    }
    return {
      keys: payload
    };
  };
  return UniqueKeysCacheInMemoryCS;
}();
export { UniqueKeysCacheInMemoryCS };