import { findIndex } from '../../utils/lang';
var Treatments = /** @class */function () {
  function Treatments(ranges, treatments) {
    if (ranges[ranges.length - 1] !== 100) {
      throw new RangeError('Provided invalid dataset as input');
    }
    this._ranges = ranges;
    this._treatments = treatments;
  }
  Treatments.parse = function (data) {
    var _a = data.reduce(function (accum, value) {
        var size = value.size,
          treatment = value.treatment;
        accum.ranges.push(accum.inc += size);
        accum.treatments.push(treatment);
        return accum;
      }, {
        inc: 0,
        ranges: [],
        treatments: []
      }),
      ranges = _a.ranges,
      treatments = _a.treatments;
    return new Treatments(ranges, treatments);
  };
  Treatments.prototype.getTreatmentFor = function (x) {
    if (x < 0 || x > 100) {
      throw new RangeError('Please provide a value between 0 and 100');
    }
    // Readme [1]
    // We need to manually add any dependency which escape of dummy resolution
    // I'll deal with this in a future release
    // for (let [k, r] of this._ranges.entries()) {
    //   if (x <= r) return this._treatments[k];
    // }
    var index = findIndex(this._ranges, function (range) {
      return x <= range;
    });
    var treatment = this._treatments[index];
    return treatment;
  };
  return Treatments;
}();
export { Treatments };