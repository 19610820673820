/**
 ********************
 * Response Mapping - Utility Functions
 ********************
 */

/**
 * Removes country code and formats the phone number to the format (XXX) XXX-XXXX
 * @example: +13333333333 to (333) 333-3333
 */
export function translateLegacyPhone(phoneNumber: string | undefined | null | number): string | undefined {
  const filteredPhoneChars = phoneNumber?.toString().replace(/\D/g, ''); // remove non-digits
  if (!filteredPhoneChars) {
    return undefined;
  }
  let digits = filteredPhoneChars;

  if (digits && digits.length > 10 && digits.startsWith('1')) {
    digits = digits.substring(1);
  }

  if (digits && digits.length !== 10) {
    return phoneNumber?.toString();
  }

  const areaCode = digits?.substring(0, 3);
  const firstThree = digits?.substring(3, 6);
  const lastFour = digits?.substring(6);

  if (!areaCode || !firstThree || !lastFour) {
    return undefined;
  }

  return `(${areaCode}) ${firstThree}-${lastFour}`;
}

/**
 * Translates the default null from the API as undefined for the UI
 */
export function translateApiNullToUndefined<T>(value: T | null): T | undefined {
  if (value === null) {
    return undefined;
  }
  return value;
}

/**
 * Translates a LegacyNumber into a number or undefined
 * @example: '0' to 0
 */
export function translateLegacyNumber(num: number | string | null | undefined): number | undefined {
  if (typeof num === 'number') {
    return num;
  }
  if (typeof num === 'string') {
    const parsed = parseFloat(num);
    return Number.isNaN(parsed) ? undefined : parsed;
  }
  return undefined;
}

export function translateLegacyStringBoolean(value: 'true' | 'false' | boolean | null | undefined): boolean | undefined {
  if (value === null) {
    return undefined;
  }
  if (value === 'true' || value === true) {
    return true;
  }
  return value === 'false' || value === false ? false : undefined;
}
