import { __extends } from "tslib";
import { AbstractSegmentsCacheSync } from '../AbstractSegmentsCacheSync';
import { LOG_PREFIX, DEFINED } from './constants';
var MySegmentsCacheInLocal = /** @class */function (_super) {
  __extends(MySegmentsCacheInLocal, _super);
  function MySegmentsCacheInLocal(log, keys) {
    var _this = _super.call(this) || this;
    _this.log = log;
    _this.keys = keys;
    return _this;
    // There is not need to flush segments cache like splits cache, since resetSegments receives the up-to-date list of active segments
  }
  /**
   * Removes list of segments from localStorage
   * @NOTE this method is not being used at the moment.
   */
  MySegmentsCacheInLocal.prototype.clear = function () {
    this.log.info(LOG_PREFIX + 'Flushing MySegments data from localStorage');
    // We cannot simply call `localStorage.clear()` since that implies removing user items from the storage
    // We could optimize next sentence, since it implies iterating over all localStorage items
    this.resetSegments([]);
  };
  MySegmentsCacheInLocal.prototype.addToSegment = function (name) {
    var segmentKey = this.keys.buildSegmentNameKey(name);
    try {
      localStorage.setItem(segmentKey, DEFINED);
      return true;
    } catch (e) {
      this.log.error(LOG_PREFIX + e);
      return false;
    }
  };
  MySegmentsCacheInLocal.prototype.removeFromSegment = function (name) {
    var segmentKey = this.keys.buildSegmentNameKey(name);
    try {
      localStorage.removeItem(segmentKey);
      return true;
    } catch (e) {
      this.log.error(LOG_PREFIX + e);
      return false;
    }
  };
  MySegmentsCacheInLocal.prototype.isInSegment = function (name) {
    return localStorage.getItem(this.keys.buildSegmentNameKey(name)) === DEFINED;
  };
  /**
   * Reset (update) the cached list of segments with the given list, removing and adding segments if necessary.
   *
   * @param {string[]} segmentNames list of segment names
   * @returns boolean indicating if the cache was updated (i.e., given list was different from the cached one)
   */
  MySegmentsCacheInLocal.prototype.resetSegments = function (names) {
    var _this = this;
    var isDiff = false;
    var index;
    // Scan current values from localStorage
    var storedSegmentNames = Object.keys(localStorage).reduce(function (accum, key) {
      var segmentName = _this.keys.extractSegmentName(key);
      if (segmentName) {
        accum.push(segmentName);
      } else {
        // @TODO @BREAKING: This is only to clean up "old" keys. Remove this whole else code block and reuse `getRegisteredSegments` method.
        segmentName = _this.keys.extractOldSegmentKey(key);
        if (segmentName) {
          // this was an old segment key, let's clean up.
          var newSegmentKey = _this.keys.buildSegmentNameKey(segmentName);
          try {
            // If the new format key is not there, create it.
            if (!localStorage.getItem(newSegmentKey) && names.indexOf(segmentName) > -1) {
              localStorage.setItem(newSegmentKey, DEFINED);
              // we are migrating a segment, let's track it.
              accum.push(segmentName);
            }
            localStorage.removeItem(key); // we migrated the current key, let's delete it.
          } catch (e) {
            _this.log.error(e);
          }
        }
      }
      return accum;
    }, []);
    // Extreme fast => everything is empty
    if (names.length === 0 && storedSegmentNames.length === names.length) return isDiff;
    // Quick path
    if (storedSegmentNames.length !== names.length) {
      isDiff = true;
      storedSegmentNames.forEach(function (name) {
        return _this.removeFromSegment(name);
      });
      names.forEach(function (name) {
        return _this.addToSegment(name);
      });
    } else {
      // Slowest path => we need to find at least 1 difference because
      for (index = 0; index < names.length && storedSegmentNames.indexOf(names[index]) !== -1; index++) {
        // TODO: why empty statement?
      }
      if (index < names.length) {
        isDiff = true;
        storedSegmentNames.forEach(function (name) {
          return _this.removeFromSegment(name);
        });
        names.forEach(function (name) {
          return _this.addToSegment(name);
        });
      }
    }
    return isDiff;
  };
  MySegmentsCacheInLocal.prototype.getRegisteredSegments = function () {
    var _this = this;
    return Object.keys(localStorage).reduce(function (accum, key) {
      var segmentName = _this.keys.extractSegmentName(key);
      if (segmentName) accum.push(segmentName);
      return accum;
    }, []);
  };
  MySegmentsCacheInLocal.prototype.getKeysCount = function () {
    return 1;
  };
  return MySegmentsCacheInLocal;
}(AbstractSegmentsCacheSync);
export { MySegmentsCacheInLocal };