import { SplitToGa } from './SplitToGa';
export function SplitToGoogleAnalytics(options) {
  if (options === void 0) {
    options = {};
  }
  // SplitToGa integration factory
  function SplitToGoogleAnalyticsFactory(params) {
    return new SplitToGa(params.settings.log, options);
  }
  SplitToGoogleAnalyticsFactory.type = 'SPLIT_TO_GOOGLE_ANALYTICS';
  return SplitToGoogleAnalyticsFactory;
}