import { objectAssign } from '../../utils/lang/objectAssign';
var AttributesCacheInMemory = /** @class */function () {
  function AttributesCacheInMemory() {
    this.attributesCache = {};
  }
  /**
   * Create or update the value for the given attribute
   *
   * @param {string} attributeName attribute name
   * @param {Object} attributeValue attribute value
   * @returns {boolean} the attribute was stored
   */
  AttributesCacheInMemory.prototype.setAttribute = function (attributeName, attributeValue) {
    this.attributesCache[attributeName] = attributeValue;
    return true;
  };
  /**
   * Retrieves the value of a given attribute
   *
   * @param {string} attributeName attribute name
   * @returns {Object?} stored attribute value
   */
  AttributesCacheInMemory.prototype.getAttribute = function (attributeName) {
    return this.attributesCache[attributeName];
  };
  /**
   * Create or update all the given attributes
   *
   * @param {[string, Object]} attributes attributes to create or update
   * @returns {boolean} attributes were stored
   */
  AttributesCacheInMemory.prototype.setAttributes = function (attributes) {
    this.attributesCache = objectAssign(this.attributesCache, attributes);
    return true;
  };
  /**
   * Retrieve the full attributes map
   *
   * @returns {Map<string, Object>} stored attributes
   */
  AttributesCacheInMemory.prototype.getAll = function () {
    return this.attributesCache;
  };
  /**
   * Removes a given attribute from the map
   *
   * @param {string} attributeName attribute to remove
   * @returns {boolean} attribute removed
   */
  AttributesCacheInMemory.prototype.removeAttribute = function (attributeName) {
    if (Object.keys(this.attributesCache).indexOf(attributeName) >= 0) {
      delete this.attributesCache[attributeName];
      return true;
    }
    return false;
  };
  /**
   * Clears all attributes stored in the SDK
   *
   */
  AttributesCacheInMemory.prototype.clear = function () {
    this.attributesCache = {};
    return true;
  };
  return AttributesCacheInMemory;
}();
export { AttributesCacheInMemory };