import { ChangeDetectionStrategy, Component, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideGoal } from '@ng-icons/lucide';
import { MaskitoDirective } from '@maskito/angular';
import { Lockable, WithLockableFields, LockedQuoteAlertComponent } from 'app/create-quote/form-locking';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { CoApplicantInfoQuoteFormInput } from './co-applicant-info.types';
import { TextInputComponent } from '../../../common/components/forms/text-input/text-input.component';
import { FormCardComponent } from '../shared/form-card.component';
import mask from '../shared/date-mask-options';
import { ageValidator } from '../shared/age.validator';
import { utilToIsoDate } from '../../../common/utils/iso-date-type/to-iso-date';

@Component({
  selector: 'app-co-applicant-info',
  standalone: true,
  imports: [ReactiveFormsModule, NgIconComponent, TextInputComponent, FormCardComponent, MaskitoDirective, LockedQuoteAlertComponent],
  providers: [provideIcons({ lucideGoal })],
  templateUrl: './co-applicant-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/co-applicant-info',
})
export class QuoteFormCoApplicantInfoComponent extends BaseQuoteForm<CoApplicantInfoQuoteFormInput> implements OnInit, Lockable {
  public formDataIn: CoApplicantInfoQuoteFormInput = this.quoteDraftStore.coApplicantInfoQuoteFormSelector();

  readonly maskitoDateOptions = mask;

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  public getFormControls() {
    return this.coApplicantInfoForm.controls;
  }

  public formDefinition = {
    firstName: new FormControl<string>('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    lastName: new FormControl<string>('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    dob: new FormControl<string>('', {
      validators: [Validators.required, Validators.minLength(10), (control) => ageValidator(control, () => this.quoteDraftStore.address()?.state)],
      updateOn: 'blur',
    }),
  };

  public coApplicantInfoForm = new FormGroup(this.formDefinition);

  public errorMessages = {
    firstName: {
      required: 'Co-applicant first name is required',
    },
    lastName: {
      required: 'Co-applicant last name is required',
    },
    dob: {
      required: 'Co-applicant date of birth is required',
      minlength: 'Please enter a valid date in MM/DD/YYYY format',
      invalidDateFormat: 'Please enter a valid date in MM/DD/YYYY format',
      underage: 'Birthdate is outside the eligible range',
    },
  };

  public ngOnInit() {
    if (this.formDataIn) {
      this.coApplicantInfoForm.patchValue(this.formDataIn);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'coApplicant'> {
    const { controls } = this.coApplicantInfoForm;
    const dobValue = controls.dob.value;
    return {
      coApplicant: {
        firstName: controls.firstName.value ?? '',
        lastName: controls.lastName.value ?? '',
        dob: dobValue ? utilToIsoDate(dobValue) : undefined,
      },
    };
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.coApplicantInfoForm.markAllAsTouched();
    if (this.coApplicantInfoForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }
}
