import { ChangeDetectionStrategy, Component, inject, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';
import { TextInputComponent } from 'app/common/components/forms/text-input/text-input.component';
import { TDateISODate } from 'app/common/utils/iso-date-type/iso-date.type';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { format } from 'date-fns';
import { Lockable, WithLockableFields, LockedQuoteAlertComponent } from 'app/create-quote/form-locking';
import { ReadonlyRadioDirective } from 'app/common/directives/readonly-radio.directive';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { dateRangeValidator, sixtyDaysFromToday } from '../shared/date-range.validator';
import { FormCardComponent } from '../shared/form-card.component';
import { PropertyInformationFormOption, PropertyInformationQuoteFormInput } from './property-information.types';
import { ReadonlySelectDirective } from '../../../common/directives/readonly-select.directive';

@Component({
  selector: 'app-property-information',
  standalone: true,
  imports: [FormCardComponent, NgIconComponent, ReactiveFormsModule, TextInputComponent, ReadonlyRadioDirective, ReadonlySelectDirective, LockedQuoteAlertComponent],
  templateUrl: './property-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideIcons({ lucideCircleAlert, lucideHouse })],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property_information',
})
export class QuoteFormPropertyInformationComponent extends BaseQuoteForm<PropertyInformationQuoteFormInput> implements OnInit, Lockable {
  private optionsStore = inject(QuoteFormOptionsStore);
  public formDataIn: PropertyInformationQuoteFormInput = this.quoteDraftStore.propertyInformationQuoteFormSelector();
  public maxDate = format(sixtyDaysFromToday(), 'yyyy-MM-dd');
  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  // only return the controls that are required
  public getFormControls() {
    return Object.fromEntries(Object.entries(this.propertyInformationForm.controls).filter(([_, control]) => control.hasValidator(Validators.required)));
  }

  public propertyInformationOptions = this.optionsStore.propertyInformationQuoteFormOptionsSelector();

  public formDefinition = {
    withinPark: new FormControl<boolean | undefined>(undefined),
    bathrooms: new FormControl<number | undefined>(undefined, [Validators.min(0)]),
    roofHasSolarPanels: new FormControl<boolean | undefined>(undefined),
    acreage: new FormControl<number | undefined>(undefined, [Validators.min(0)]),
    purchaseDate: new FormControl<TDateISODate | undefined>(undefined, {
      validators: [dateRangeValidator(null, this.maxDate)],
      updateOn: 'blur',
    }),
    roofType: new FormControl<string | undefined>(undefined),
  };
  public propertyInformationForm = new FormGroup(this.formDefinition);

  public ngOnInit() {
    if (this.formDataIn) {
      this.propertyInformationForm.controls.withinPark.setValue(this.formDataIn.withinPark);
      this.propertyInformationForm.controls.bathrooms.setValue(this.formDataIn.bathrooms);
      this.propertyInformationForm.controls.roofHasSolarPanels.setValue(this.formDataIn.roofHasSolarPanels);
      this.propertyInformationForm.controls.acreage.setValue(this.formDataIn.acreage);
      this.propertyInformationForm.controls.purchaseDate.setValue(this.formDataIn.purchaseDate);
      this.propertyInformationForm.controls.roofType.setValue(this.formDataIn.roofType);
    }

    this.setConditionalRequiredValidator('withinPark', 'within_park');
    this.setConditionalRequiredValidator('bathrooms', 'bathrooms');
    this.setConditionalRequiredValidator('roofHasSolarPanels', 'roof_solar_panels');
    this.setConditionalRequiredValidator('acreage', 'acreage');
    this.setConditionalRequiredValidator('purchaseDate', 'purchase_date');
    this.setConditionalRequiredValidator('roofType', 'roof_type');
  }

  private setConditionalRequiredValidator(controlName: string, optionName: string) {
    const control = this.propertyInformationForm.get(controlName);
    if (control) {
      if (this.hasOption(optionName)) {
        control.addValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
      }
      control.updateValueAndValidity();
    }
  }

  public hasOption(id: string): boolean {
    if (!this.propertyInformationOptions) {
      return false;
    }
    return this.propertyInformationOptions.find((option: PropertyInformationFormOption) => option.id === id) !== undefined;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.propertyInformationForm.markAllAsTouched();
    if (this.propertyInformationForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }
  public showError(control: FormControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  private getFormValues() {
    const { controls } = this.propertyInformationForm;
    const purchaseDate = controls.purchaseDate.value ?? undefined;
    return {
      withinPark: controls.withinPark.value ?? undefined,
      bathrooms: controls.bathrooms.value ?? undefined,
      roofHasSolarPanels: controls.roofHasSolarPanels.value ?? undefined,
      acreage: controls.acreage.value ?? undefined,
      purchaseDate,
      roofType: controls.roofType.value ?? undefined,
    };
  }

  public getRoofTypeOptions(): { value: string; label: string }[] {
    const options = this.propertyInformationOptions.find((option: PropertyInformationFormOption) => option.id === 'roof_type')?.selectOptions;
    if (options && options.length < 1) {
      this.logger.error({ message: 'no roof type options found', context: 'QuoteFormPropertyInformationComponent', priority: 'P3' });
    }
    return options ?? [];
  }

  public errorMessages = {
    withinPark: {
      required: 'Please specify if the property is within a manufactured home park',
    },
    bathrooms: {
      required: 'Please enter the number of bathrooms',
      min: 'Bathrooms must be greater than 0',
    },
    roofHasSolarPanels: {
      required: 'Please specify if the property has solar panels',
    },
    acreage: {
      required: 'Please enter the acreage',
      min: 'Acreage must be greater than 0',
    },
    purchaseDate: {
      required: 'Please enter the purchase date',
      dateRange: 'Purchase date cannot be more than 60 days from today',
    },
    roofType: {
      required: 'Please select the roof type',
    },
  };
}
