import { truncateTimeFrame } from '../../utils/time';
/**
 * Optimized strategy for impressions tracker. Wraps impressions to store and adds previousTime if it corresponds
 *
 * @param impressionsObserver impression observer. previous time (pt property) is included in impression instances
 * @param impressionsCounter cache to save impressions count. impressions will be deduped (OPTIMIZED mode)
 * @returns IStrategyResult
 */
export function strategyOptimizedFactory(impressionsObserver, impressionsCounter) {
  return {
    process: function (impressions) {
      var impressionsToStore = [];
      impressions.forEach(function (impression) {
        impression.pt = impressionsObserver.testAndSet(impression);
        var now = Date.now();
        // Increments impression counter per featureName
        if (impression.pt) impressionsCounter.track(impression.feature, now, 1);
        // Checks if the impression should be added in queue to be sent
        if (!impression.pt || impression.pt < truncateTimeFrame(now)) {
          impressionsToStore.push(impression);
        }
      });
      return {
        impressionsToStore: impressionsToStore,
        impressionsToListener: impressions,
        deduped: impressions.length - impressionsToStore.length
      };
    }
  };
}