import { ChangeDetectionStrategy, Component, inject, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideArrowRightLeft, lucideCircleAlert, lucideCircleHelp, lucideClock, lucideGoal, lucideHousePlus, lucideKeyRound, lucidePhone, lucideShieldX } from '@ng-icons/lucide';
import * as cc from 'app/common/constants/contact-constants';
import { PhonePipe } from 'app/common/pipes/phone-number/phone.pipe';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { ContactDialogService } from 'app/create-quote/services/contact-dialog/contact-dialog.service';
import { Lockable, WithLockableFields, LockedQuoteAlertComponent } from 'app/create-quote/form-locking';
import { ReadonlyRadioDirective } from 'app/common/directives/readonly-radio.directive';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';
import { InsuranceStatusQuoteFormInput, InsuranceStatusValue } from './insurance-status.types';

@Component({
  selector: 'app-insurance-status',
  standalone: true,
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent, PhonePipe, ReadonlyRadioDirective, LockedQuoteAlertComponent],
  templateUrl: './insurance-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideIcons({ lucideArrowRightLeft, lucideShieldX, lucideHousePlus, lucideKeyRound, lucideClock, lucideGoal, lucideCircleHelp, lucideCircleAlert, lucidePhone })],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/insurance_status',
})
export class QuoteFormInsuranceStatusComponent extends BaseQuoteForm<InsuranceStatusQuoteFormInput> implements OnInit, Lockable {
  private optionsStore = inject(QuoteFormOptionsStore);
  protected contactDialogService = inject(ContactDialogService);
  public formDataIn: InsuranceStatusQuoteFormInput = this.quoteDraftStore.insuranceStatusQuoteFormSelector();

  public insuranceStatusOptions = this.optionsStore.insuranceStatusQuoteFormOptionsSelector();

  public formDefinition = {
    insuranceStatus: new FormControl<InsuranceStatusValue | undefined>(undefined, [Validators.required]),
  };
  public insuranceStatusForm = new FormGroup(this.formDefinition);

  public salesPhoneNumber = cc.PHONE_NUMBERS.sales;
  public salesPhoneHoursFormatted = cc.PHONE_HOURS_FORMATTED.sales;

  public ngOnInit() {
    if (this.formDataIn) {
      this.insuranceStatusForm.controls.insuranceStatus.setValue(this.formDataIn.insuranceStatus);
    }
  }

  public getFormControls() {
    return this.insuranceStatusForm.controls;
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  private getFormValues(): Pick<QuoteDraftState, 'insuranceStatus'> {
    const { controls } = this.insuranceStatusForm;
    const selectedId = controls.insuranceStatus.value;
    const formValues: Pick<QuoteDraftState, 'insuranceStatus'> = {
      insuranceStatus: selectedId as InsuranceStatusValue,
    };

    return formValues;
  }

  public get showError(): boolean {
    const control = this.insuranceStatusForm.get('insuranceStatus');
    return !!control?.invalid && !!control?.touched;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.insuranceStatusForm.markAllAsTouched();
    if (this.insuranceStatusForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }
}
