<div class="qui-form-layout__col-center">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert class="mb-400 block"></app-locked-quote-alert>
  }
  <app-form-card title="Do you want to add a co-applicant?" icon="lucideGoal">
    <ng-template #subcopyTemplate>
      <p class="m-b-300">
        Add a spouse, partner, or co-owner as a co-applicant to ensure they are covered. This provides shared protection, simplifies claims, and offers added convenience for all parties.
      </p>
      <p>
        If the co-applicant is not a person (like a Trust, LLC, etc.) please
        <button (click)="contactDialogService.open()" class="button button--link" aria-label="Call Kin about your quote at 855-717-0022" data-testid="call-us-button">call us</button> to add them to
        your policy.
      </p>
    </ng-template>

    <form [formGroup]="coApplicantForm" (submit)="handleSubmit($event)" id="quote-form" class="flex w-full flex-col self-start">
      <fieldset class="form-group__fieldset" [appReadonlyRadioGroup]="isFieldLocked('hasCoApplicant')">
        <legend class="sr-only">Do you want to add a co-applicant?</legend>
        <div class="mt-6 flex flex-row gap-100 sm:mt-10 sm:gap-400">
          <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="co-applicant-yes" [class.radio-card--invalid]="showError">
            <input [attr.readonly]="isFieldLocked('hasCoApplicant')" class="radio-card__control peer outline-none" type="radio" id="co-applicant-yes" [value]="true" formControlName="hasCoApplicant" />
            <span for="co-applicant-yes">Yes</span>
          </label>
          <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="co-applicant-no" [class.radio-card--invalid]="showError">
            <input class="radio-card__control peer outline-none" type="radio" id="co-applicant-no" [value]="false" formControlName="hasCoApplicant" />
            <span for="co-applicant-no">No</span>
          </label>
        </div>
      </fieldset>

      <div aria-live="polite" aria-atomic="true">
        @if (showFormRequiredError()) {
          <div class="form-help form-help--invalid flex items-center gap-100">
            <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
            <span class="error-message">Please select a co-applicant option.</span>
          </div>
        }
      </div>
    </form>
  </app-form-card>
</div>
