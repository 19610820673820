import { Injectable, Signal, signal } from '@angular/core';

/**
 * Have you wondered "how do I know if this button is actually visible?", "can the user see this element or is it behind the sticky footer?"
 * This is the service for you. Give it an element and the footer height and all your problems will be solved.
 *
 *
 * For context: at the time of writing, the fate of the 'sticky' footer is uncertain and may be removed in the future.
 * This implementation includes the footer height in it's API so that it can easily be updated if the footer is removed
 * or dynamically shown via feature flags.
 */
@Injectable({
  providedIn: 'root',
})
export class ElementVisibilityService {
  private isVisible = signal(false);
  private intersectionObserver: IntersectionObserver | null = null;
  private resizeObserver: ResizeObserver | null = null;

  observeElement(element: HTMLElement, footerElement: HTMLElement) {
    this.cleanup();

    const updateVisibility = () => {
      if (footerElement) {
        this.setupIntersectionObserver(element, footerElement.offsetHeight);
      }
    };

    this.resizeObserver = new ResizeObserver(updateVisibility);
    this.resizeObserver.observe(footerElement);

    // Initial setup
    updateVisibility();
  }

  private setupIntersectionObserver(element: HTMLElement, footerHeight: number) {
    this.intersectionObserver?.disconnect();

    this.intersectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio === 1) {
          this.isVisible.set(true);
          this.intersectionObserver?.disconnect();
        } else {
          this.isVisible.set(false);
        }
      },
      {
        root: null,
        threshold: 1,
        rootMargin: `0px 0px -${footerHeight}px 0px`,
      },
    );

    this.intersectionObserver.observe(element);
  }

  getVisibility(): Signal<boolean> {
    return this.isVisible.asReadonly();
  }

  private cleanup() {
    this.intersectionObserver?.disconnect();
    this.resizeObserver?.disconnect();
    this.isVisible.set(false);
  }

  reset() {
    this.cleanup();
  }
}
