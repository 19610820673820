<div class="form-control">
  <label class="input" [class.input--invalid]="errorMessage">
    <span [class]="'input__label ' + (control.touched ? 'text-neutral-900' : 'text-neutral-700')">
      {{ label }}
    </span>
    <input
      [id]="id"
      [name]="id"
      [type]="type"
      [formControl]="control"
      [placeholder]="placeholder"
      [required]="required"
      [pattern]="pattern"
      (blur)="onBlur($event)"
      [readonly]="readonly"
      [min]="min"
      [max]="max"
      [attr.inputmode]="inputmode"
      class="input__control"
      [attr.aria-describedby]="helpText || errorMessage ? id + '-message' : null"
      [attr.aria-invalid]="errorMessage ? 'true' : 'false'"
      (keypress)="onKeyPress($event)"
    />
  </label>

  <div [id]="id + '-message'" [class]="'form-help ' + (control.touched ? 'text-neutral-900' : 'text-neutral-700')" aria-live="polite" aria-atomic="true">
    @if (!errorMessage && helpText) {
      <ng-container>
        {{ helpText }}
      </ng-container>
    }
    @if (errorMessage) {
      <div data-testid="error-message" class="error-message form-help--invalid flex items-center gap-100" [id]="id + '-error'">
        <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>

        <ng-container *ngTemplateOutlet="customErrorTemplate || defaultErrorMessage; context: { $implicit: errorMessage }"></ng-container>

        <ng-template #defaultErrorMessage>
          {{ errorMessage }}
        </ng-template>
      </div>
    }
  </div>
</div>
