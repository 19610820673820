import { computed } from '@angular/core';
import { Lockable } from './lockable.types';

export const WithLockableFields = {
  // Determines which fields are locked based off the form controls
  // and the state of the submittedFields data in the store.
  createIsLocked(this: Lockable) {
    return computed(() => {
      const controls = this.getFormControls() || {};
      return Object.keys(controls).reduce(
        (acc, fieldName) => ({
          ...acc,
          [fieldName]: this.isFieldSubmitted(fieldName),
        }),
        {},
      );
    });
  },

  anyFieldLocked(this: Lockable): boolean {
    return Object.values(this.isLocked()).some((value) => value);
  },

  isFieldLocked(this: Lockable, fieldName: string): boolean {
    return this.isLocked()[fieldName];
  },

  allFieldsLocked(this: Lockable): boolean {
    return Object.values(this.isLocked()).every((value) => value);
  },
};
