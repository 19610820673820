import { fromObjectSyncTaskFactory } from './syncTasks/fromObjectSyncTask';
import { objectAssign } from '../../utils/lang/objectAssign';
import { SDK_SEGMENTS_ARRIVED } from '../../readiness/constants';
function flush() {
  return Promise.resolve();
}
/**
 * Offline SyncManager factory.
 * Can be used for server-side API, and client-side API with or without multiple clients.
 *
 * @param splitsParser e.g., `splitsParserFromFile`, `splitsParserFromSettings`.
 */
export function syncManagerOfflineFactory(splitsParserFactory) {
  /**
   * SyncManager factory for modular SDK
   */
  return function (_a) {
    var settings = _a.settings,
      readiness = _a.readiness,
      storage = _a.storage;
    return objectAssign(fromObjectSyncTaskFactory(splitsParserFactory(), storage, readiness, settings), {
      // fake flush, that resolves immediately
      flush: flush,
      // [Only used for client-side]
      shared: function (matchingKey, readinessManager) {
        return {
          start: function () {
            // In LOCALHOST mode, shared clients are ready in the next event-loop cycle than created
            // SDK_READY cannot be emitted directly because this will not update the readiness status
            setTimeout(function () {
              readinessManager.segments.emit(SDK_SEGMENTS_ARRIVED); // SDK_SPLITS_ARRIVED emitted by main SyncManager
            }, 0);
          },
          stop: function () {},
          isRunning: function () {
            return true;
          },
          flush: flush
        };
      }
    });
  };
}