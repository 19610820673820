import { getTreatment, shouldApplyRollout } from './engineUtils';
import { thenable } from '../../utils/promise/thenable';
import { NOT_IN_SPLIT } from '../../utils/labels';
// Build Evaluation object if and only if matchingResult is true
function match(log, matchingResult, bucketingKey, seed, treatments, label) {
  if (matchingResult) {
    var treatment = getTreatment(log, bucketingKey, seed, treatments);
    return {
      treatment: treatment,
      label: label
    };
  }
  // else we should notify the engine to continue evaluating
  return undefined;
}
// Condition factory
export function conditionContext(log, matcherEvaluator, treatments, label, conditionType) {
  return function conditionEvaluator(key, seed, trafficAllocation, trafficAllocationSeed, attributes, splitEvaluator) {
    // Whitelisting has more priority than traffic allocation, so we don't apply this filtering to those conditions.
    if (conditionType === 'ROLLOUT' && !shouldApplyRollout(trafficAllocation, key.bucketingKey, trafficAllocationSeed)) {
      return {
        treatment: undefined,
        label: NOT_IN_SPLIT
      };
    }
    // matcherEvaluator could be Async, this relays on matchers return value, so we need
    // to verify for thenable before play with the result.
    // Also, we pass splitEvaluator function in case we have a matcher that needs to evaluate another split,
    // as well as the entire key object for the same reason.
    var matches = matcherEvaluator(key, attributes, splitEvaluator);
    if (thenable(matches)) {
      return matches.then(function (result) {
        return match(log, result, key.bucketingKey, seed, treatments, label);
      });
    }
    return match(log, matches, key.bucketingKey, seed, treatments, label);
  };
}