import { __extends } from "tslib";
import { AbstractSplitsCacheSync, usesSegments } from '../AbstractSplitsCacheSync';
import { isFiniteNumber } from '../../utils/lang';
import { _Set } from '../../utils/lang/sets';
/**
 * Default ISplitsCacheSync implementation that stores split definitions in memory.
 * Supported by all JS runtimes.
 */
var SplitsCacheInMemory = /** @class */function (_super) {
  __extends(SplitsCacheInMemory, _super);
  function SplitsCacheInMemory(splitFiltersValidation) {
    var _this = _super.call(this) || this;
    _this.splitsCache = {};
    _this.ttCache = {};
    _this.changeNumber = -1;
    _this.splitsWithSegmentsCount = 0;
    _this.flagSetsCache = {};
    _this.flagSetsFilter = splitFiltersValidation ? splitFiltersValidation.groupedFilters.bySet : [];
    return _this;
  }
  SplitsCacheInMemory.prototype.clear = function () {
    this.splitsCache = {};
    this.ttCache = {};
    this.changeNumber = -1;
    this.splitsWithSegmentsCount = 0;
  };
  SplitsCacheInMemory.prototype.addSplit = function (name, split) {
    var previousSplit = this.getSplit(name);
    if (previousSplit) {
      // We had this Split already
      var previousTtName = previousSplit.trafficTypeName;
      this.ttCache[previousTtName]--;
      if (!this.ttCache[previousTtName]) delete this.ttCache[previousTtName];
      this.removeFromFlagSets(previousSplit.name, previousSplit.sets);
      if (usesSegments(previousSplit)) {
        // Substract from segments count for the previous version of this Split.
        this.splitsWithSegmentsCount--;
      }
    }
    if (split) {
      // Store the Split.
      this.splitsCache[name] = split;
      // Update TT cache
      var ttName = split.trafficTypeName;
      this.ttCache[ttName] = (this.ttCache[ttName] || 0) + 1;
      this.addToFlagSets(split);
      // Add to segments count for the new version of the Split
      if (usesSegments(split)) this.splitsWithSegmentsCount++;
      return true;
    } else {
      return false;
    }
  };
  SplitsCacheInMemory.prototype.removeSplit = function (name) {
    var split = this.getSplit(name);
    if (split) {
      // Delete the Split
      delete this.splitsCache[name];
      var ttName = split.trafficTypeName;
      this.ttCache[ttName]--; // Update tt cache
      if (!this.ttCache[ttName]) delete this.ttCache[ttName];
      this.removeFromFlagSets(split.name, split.sets);
      // Update the segments count.
      if (usesSegments(split)) this.splitsWithSegmentsCount--;
      return true;
    } else {
      return false;
    }
  };
  SplitsCacheInMemory.prototype.getSplit = function (name) {
    return this.splitsCache[name] || null;
  };
  SplitsCacheInMemory.prototype.setChangeNumber = function (changeNumber) {
    this.changeNumber = changeNumber;
    return true;
  };
  SplitsCacheInMemory.prototype.getChangeNumber = function () {
    return this.changeNumber;
  };
  SplitsCacheInMemory.prototype.getSplitNames = function () {
    return Object.keys(this.splitsCache);
  };
  SplitsCacheInMemory.prototype.trafficTypeExists = function (trafficType) {
    return isFiniteNumber(this.ttCache[trafficType]) && this.ttCache[trafficType] > 0;
  };
  SplitsCacheInMemory.prototype.usesSegments = function () {
    return this.getChangeNumber() === -1 || this.splitsWithSegmentsCount > 0;
  };
  SplitsCacheInMemory.prototype.getNamesByFlagSets = function (flagSets) {
    var _this = this;
    return flagSets.map(function (flagSet) {
      return _this.flagSetsCache[flagSet] || new _Set();
    });
  };
  SplitsCacheInMemory.prototype.addToFlagSets = function (featureFlag) {
    var _this = this;
    if (!featureFlag.sets) return;
    featureFlag.sets.forEach(function (featureFlagSet) {
      if (_this.flagSetsFilter.length > 0 && !_this.flagSetsFilter.some(function (filterFlagSet) {
        return filterFlagSet === featureFlagSet;
      })) return;
      if (!_this.flagSetsCache[featureFlagSet]) _this.flagSetsCache[featureFlagSet] = new _Set([]);
      _this.flagSetsCache[featureFlagSet].add(featureFlag.name);
    });
  };
  SplitsCacheInMemory.prototype.removeFromFlagSets = function (featureFlagName, flagSets) {
    var _this = this;
    if (!flagSets) return;
    flagSets.forEach(function (flagSet) {
      _this.removeNames(flagSet, featureFlagName);
    });
  };
  SplitsCacheInMemory.prototype.removeNames = function (flagSetName, featureFlagName) {
    if (!this.flagSetsCache[flagSetName]) return;
    this.flagSetsCache[flagSetName].delete(featureFlagName);
    if (this.flagSetsCache[flagSetName].size === 0) delete this.flagSetsCache[flagSetName];
  };
  return SplitsCacheInMemory;
}(AbstractSplitsCacheSync);
export { SplitsCacheInMemory };