/* eslint-disable no-unused-labels */

import { ChangeDetectionStrategy, Component, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideGoal } from '@ng-icons/lucide';
import { TextInputComponent } from 'app/common/components/forms/text-input/text-input.component';
import { format } from 'date-fns';
import { Lockable, WithLockableFields, LockedQuoteAlertComponent } from 'app/create-quote/form-locking';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';
import { PolicyStartDateQuoteFormInput } from './policy-start-date.types';
import { dateRangeValidator, fourteenDaysFromToday, sixtyDaysFromToday, today } from '../shared/date-range.validator';
import { TDateISODate } from '../../../common/utils/iso-date-type/iso-date.type';

@Component({
  selector: 'app-policy-start-date',
  standalone: true,
  imports: [FormCardComponent, NgIconComponent, ReactiveFormsModule, TextInputComponent, LockedQuoteAlertComponent],
  templateUrl: './policy-start-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [provideIcons({ lucideGoal, lucideCircleAlert })],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/policy_start_date',
})
export class QuoteFormPolicyStartDateComponent extends BaseQuoteForm<PolicyStartDateQuoteFormInput> implements OnInit, Lockable {
  public formDataIn: PolicyStartDateQuoteFormInput = { policyStartDate: this.quoteDraftStore.policyStartDate() };

  public minDate = format(today(), 'yyyy-MM-dd') as TDateISODate;
  public maxDate = format(sixtyDaysFromToday(), 'yyyy-MM-dd') as TDateISODate;
  public preselectedDate = format(fourteenDaysFromToday(), 'yyyy-MM-dd') as TDateISODate;

  public formDefinition = {
    policyStartDate: new FormControl<TDateISODate | undefined>(this.preselectedDate, [Validators.required, dateRangeValidator(this.minDate, this.maxDate)]),
  };
  public policyStartDateForm = new FormGroup(this.formDefinition);

  public ngOnInit() {
    if (this.formDataIn.policyStartDate) {
      this.policyStartDateForm.controls.policyStartDate.setValue(this.formDataIn.policyStartDate);
    }
  }

  public getFormValues(): Pick<QuoteDraftState, 'policyStartDate'> {
    const { controls } = this.policyStartDateForm;
    const policyStartDateValue = controls.policyStartDate.value as TDateISODate;
    const formValues = {
      policyStartDate: policyStartDateValue ?? undefined,
    };

    return formValues;
  }

  public getFormControls() {
    return this.policyStartDateForm.controls;
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public allFieldsLocked = WithLockableFields.allFieldsLocked;
  public isFieldLocked = WithLockableFields.isFieldLocked;

  public handleSubmit(e: Event) {
    e.preventDefault();
    if (this.policyStartDateForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }

  public errorMessages = {
    policyStartDate: {
      required: 'Please make a selection',
      dateRange: 'Please choose a date within the next 60 days',
    },
  };
}
