import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, Signal, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconLoader } from '@ng-icons/core';
import { lucideBadgeDollarSign, lucideCircleAlert, lucideGoal, lucideShieldCheck } from '@ng-icons/lucide';
import { LegacyCustomerInputResponseService } from 'app/common/services/legacy/customer-input-response/customer-input-response.service';
import { LegacyPagesApiService } from 'app/common/services/legacy/quote-pages';
import { QuoteDraftState } from 'app/global-store/quote-draft/quote-draft.model';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { BehaviorSubject, filter, take } from 'rxjs';
import { LockedQuoteAlertComponent, Lockable, WithLockableFields } from 'app/create-quote/form-locking';
import { ReadonlyRadioDirective } from 'app/common/directives/readonly-radio.directive';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { FormCardComponent } from '../shared/form-card.component';
import type { PolicyGoalValue, PolicyGoalsQuoteFormInput } from './policy-goals.types';

@Component({
  selector: 'app-policy-goals',
  standalone: true,
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent, ReadonlyRadioDirective, LockedQuoteAlertComponent],
  templateUrl: './policy-goals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    provideIcons({ lucideGoal, lucideCircleAlert, lucideBadgeDollarSign, lucideShieldCheck }),
    provideNgIconLoader((name) => {
      const http = inject(HttpClient);
      return http.get(`/assets/icons/${name}.svg`, { responseType: 'text' });
    }),
  ],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class QuoteFormPolicyGoalsComponent extends BaseQuoteForm<PolicyGoalsQuoteFormInput> implements OnInit, Lockable {
  public formDataIn: PolicyGoalsQuoteFormInput = this.quoteDraftStore.policyGoalsQuoteFormSelector();

  public policyGoalOptions = [
    {
      icon: 'lucideBadgeDollarSign',
      label: 'Lower price',
      value: 'lower_price',
      id: 'lower_price',
      description: 'Designed with affordability as the main priority. This may mean more basic coverage and higher out-of-pocket costs if a claim is needed, but it keeps premiums as low as possible.',
    },
    {
      icon: 'lucideShieldCheck',
      label: 'Better protection',
      value: 'better_protection',
      id: 'better_protection',
      description:
        'Focuses on providing comprehensive coverage. Although premiums may be higher, this option is designed to minimize your out-of-pocket costs if a claim is needed, giving you added financial security and peace of mind.',
    },
    {
      icon: 'shield-dollar',
      label: 'I care equally about price and protection',
      value: 'both',
      id: 'both',
      description: 'Balances affordability with robust coverage. Your policy will offer moderate premiums and out-of-pocket costs, giving you good protection without stretching your budget too far.',
    },
  ];

  public formDefinition = {
    policyGoal: new FormControl<PolicyGoalValue | undefined>(undefined, Validators.required),
  };
  public policyGoalsForm = new FormGroup(this.formDefinition);

  private pagesService = inject(LegacyPagesApiService);
  private customerInputResponseService = inject(LegacyCustomerInputResponseService);
  public localPagePath: string | undefined;
  private optionsStore = inject(QuoteFormOptionsStore);
  private destroyRef = inject(DestroyRef);

  private localPagePathSubject = new BehaviorSubject<string | undefined>(undefined);
  private localPagePath$ = this.localPagePathSubject.asObservable().pipe(
    filter((path): path is string => path !== undefined),
    take(1),
  );

  public override formKey = 'policy-goals';

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  public ngOnInit() {
    if (this.formDataIn.policyGoal) {
      this.policyGoalsForm.controls.policyGoal.setValue(this.formDataIn.policyGoal);
    }

    /**
     * This component is an edge case in that it is manually injected right after the About You page and
     * taken out of the normal flow.
     * On init, it will make a request to the Pages API to find the current next page.
     * When the user submits, it's possible the request is still pending so we wrap it in an observable
     * to ensure we don't navigate until the request is complete.
     */
    if (!this.isFieldLocked('policyGoal')) {
      this.pagesService
        .pollForNextPage()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (resp) => {
            if (!resp.page) {
              this.logger.error({ message: `Pages API response did not include a page`, context: 'QuoteFormPolicyGoalsComponent', priority: 'P3' });
              return;
            }

            const formOptions = this.optionsStore.getLegacyOptionsForForm(resp.page.path, resp.page.components, this.logger);
            this.optionsStore.updateOptionsForFormByLegacyPath(resp.page.path, formOptions, this.logger);
            this.localPagePathSubject.next(resp.page.path);
          },
          error: (resp: HttpErrorResponse) => {
            const msg = resp.message && resp.message !== '' ? resp.message : 'Unexpected Error';
            this.logger.error({ message: `Error getting next page from Pages API: ${msg}`, context: 'QuoteFormPolicyGoalsComponent', priority: 'P3' });
          },
        });
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'policyGoal'> {
    const { controls } = this.policyGoalsForm;
    const formValues = {
      policyGoal: controls.policyGoal.value ?? undefined,
    };

    return formValues;
  }

  public getFormControls() {
    return this.policyGoalsForm.controls;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.policyGoalsForm.markAllAsTouched();

    if (this.policyGoalsForm.valid) {
      if (this.isFieldLocked('policyGoal')) {
        super.navigateForward();
      } else {
        this.quoteDraftStore.patchStore(this.getFormValues());
        this.customerInputResponseService.updateCustomerInputResponse({ policy_goal: this.getFormValues().policyGoal! }).subscribe({
          next: () => {
            this.localPagePath$.subscribe((path) => {
              this.markFieldsAsSubmitted(Object.keys(this.getFormControls()), 'policy-goals');
              this.router.navigate([`quote/legacy/`, path]);
            });
          },
          error: (error) => {
            this.logger.error({
              message: `Error updating customer input response. Error message: ${error?.message}. Error stack: ${error?.stack}`,
              context: 'QuoteFormPolicyGoalsComponent',
              priority: 'P3',
            });
            this.router.navigate(['/404']);
          },
        });
      }
    }
  }

  public get showError(): boolean {
    const control = this.policyGoalsForm.get('policyGoal');
    return !!control?.invalid && !!control?.touched;
  }
}
