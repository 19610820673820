import { findIndex } from '../../utils/lang';
import { thenable } from '../../utils/promise/thenable';
import { UNSUPPORTED_MATCHER_TYPE } from '../../utils/labels';
import { CONTROL } from '../../utils/constants';
import { ENGINE_COMBINER_IFELSEIF, ENGINE_COMBINER_IFELSEIF_NO_TREATMENT, ERROR_ENGINE_COMBINER_IFELSEIF } from '../../logger/constants';
export function ifElseIfCombinerContext(log, predicates) {
  function unexpectedInputHandler() {
    log.error(ERROR_ENGINE_COMBINER_IFELSEIF);
    return {
      treatment: CONTROL,
      label: UNSUPPORTED_MATCHER_TYPE
    };
  }
  function computeTreatment(predicateResults) {
    var len = predicateResults.length;
    for (var i = 0; i < len; i++) {
      var evaluation = predicateResults[i];
      if (evaluation !== undefined) {
        log.debug(ENGINE_COMBINER_IFELSEIF, [evaluation.treatment]);
        return evaluation;
      }
    }
    log.debug(ENGINE_COMBINER_IFELSEIF_NO_TREATMENT);
    return undefined;
  }
  function ifElseIfCombiner(key, seed, trafficAllocation, trafficAllocationSeed, attributes, splitEvaluator) {
    // In Async environments we are going to have async predicates. There is none way to know
    // before hand so we need to evaluate all the predicates, verify for thenables, and finally,
    // define how to return the treatment (wrap result into a Promise or not).
    var predicateResults = predicates.map(function (evaluator) {
      return evaluator(key, seed, trafficAllocation, trafficAllocationSeed, attributes, splitEvaluator);
    });
    // if we find a thenable
    if (findIndex(predicateResults, thenable) !== -1) {
      return Promise.all(predicateResults).then(function (results) {
        return computeTreatment(results);
      });
    }
    return computeTreatment(predicateResults);
  }
  // if there is none predicates, then there was an error in parsing phase
  if (!Array.isArray(predicates) || predicates.length === 0) {
    return unexpectedInputHandler;
  } else {
    return ifElseIfCombiner;
  }
}