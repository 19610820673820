<form [formGroup]="unitNumberEntryForm" class="qui-form-layout__col-center flex flex-col gap-400" (submit)="handleSubmit($event)" id="quote-form">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card [title]="'What is your unit number?'" icon="lucideHouse">
    <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 md:gap-y-600">
      <app-text-input
        [readonly]="isFieldLocked('unitNumber')"
        [control]="unitNumberEntryForm.controls.unitNumber"
        [required]="true"
        [placeholder]="'Unit #'"
        [type]="'text'"
        [errorMessages]="errorMessages.unitNumber"
        data-testid="unit-number-entry"
      ></app-text-input>
    </div>
  </app-form-card>
</form>
