import { groupBy, forOwn } from '../../utils/lang';
import { submitterFactory } from './submitter';
import { SUBMITTERS_PUSH_FULL_QUEUE } from '../../logger/constants';
var DATA_NAME = 'impressions';
/**
 * Converts `impressions` data from cache into request payload.
 */
export function fromImpressionsCollector(sendLabels, data) {
  var groupedByFeature = groupBy(data, 'feature');
  var dto = [];
  // using forOwn instead of for...in since the last also iterates over prototype enumerables
  forOwn(groupedByFeature, function (value, name) {
    dto.push({
      f: name,
      i: value.map(function (entry) {
        var keyImpression = {
          k: entry.keyName,
          t: entry.treatment,
          m: entry.time,
          c: entry.changeNumber,
          r: sendLabels ? entry.label : undefined,
          b: entry.bucketingKey ? entry.bucketingKey : undefined,
          pt: entry.pt ? entry.pt : undefined // Previous time
        };
        return keyImpression;
      })
    });
  });
  return dto;
}
/**
 * Submitter that periodically posts impressions data
 */
export function impressionsSubmitterFactory(params) {
  var _a = params.settings,
    log = _a.log,
    impressionsRefreshRate = _a.scheduler.impressionsRefreshRate,
    labelsEnabled = _a.core.labelsEnabled,
    postTestImpressionsBulk = params.splitApi.postTestImpressionsBulk,
    impressions = params.storage.impressions;
  // retry impressions only once.
  var syncTask = submitterFactory(log, postTestImpressionsBulk, impressions, impressionsRefreshRate, DATA_NAME, fromImpressionsCollector.bind(undefined, labelsEnabled), 1);
  // register impressions submitter to be executed when impressions cache is full
  impressions.setOnFullQueueCb(function () {
    if (syncTask.isRunning()) {
      log.info(SUBMITTERS_PUSH_FULL_QUEUE, [DATA_NAME]);
      syncTask.execute();
    }
    // If submitter is stopped (e.g., user consent declined or unknown, or app state offline), we don't send the data.
    // Data will be sent when submitter is resumed.
  });
  return syncTask;
}