import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconLoader } from '@ng-icons/core';
import { lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone } from '@ng-icons/lucide';
import * as cc from 'app/common/constants/contact-constants';
import { PhonePipe } from 'app/common/pipes/phone-number/phone.pipe';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { ContactDialogService } from 'app/create-quote/services/contact-dialog/contact-dialog.service';
import { Lockable, WithLockableFields } from 'app/create-quote/form-locking';
import { ReadonlyRadioDirective } from 'app/common/directives/readonly-radio.directive';
import { QuoteDraftState } from '../../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { FormCardComponent } from '../../shared/form-card.component';
import { FoundationTypeQuoteFormInput, FoundationTypeValue } from './foundation.types';
import { LockedQuoteAlertComponent } from '../../../form-locking/locked-quote-alert.component';

@Component({
  selector: 'app-foundation',
  standalone: true,
  imports: [FormCardComponent, NgIconComponent, ReactiveFormsModule, PhonePipe, LockedQuoteAlertComponent, ReadonlyRadioDirective],
  templateUrl: './foundation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    provideIcons({ lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone }),
    provideNgIconLoader((filename) => {
      const http = inject(HttpClient);
      return http.get(`/assets/icons/${filename}`, { responseType: 'text' });
    }),
  ],
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/foundation',
})
export class QuoteFormFoundationTypeComponent extends BaseQuoteForm<FoundationTypeQuoteFormInput> implements OnInit, Lockable {
  private optionsStore = inject(QuoteFormOptionsStore);
  protected contactDialogService = inject(ContactDialogService);
  public formDataIn: FoundationTypeQuoteFormInput = this.quoteDraftStore.foundationTypeQuoteFormSelector();

  public foundationTypeOptions = this.optionsStore.foundationTypeQuoteFormOptionsSelector();

  public formDefinition = {
    foundationType: new FormControl<FoundationTypeValue | undefined>(undefined, Validators.required),
  };
  public foundationTypeForm = new FormGroup(this.formDefinition);

  public salesPhoneNumber = cc.PHONE_NUMBERS.sales;
  public salesPhoneHoursFormatted = cc.PHONE_HOURS_FORMATTED.sales;

  public ngOnInit() {
    if (this.formDataIn.foundationType) {
      this.foundationTypeForm.controls.foundationType.setValue(this.formDataIn.foundationType);
    }
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  private getFormValues(): Pick<QuoteDraftState, 'foundationType'> {
    const { controls } = this.foundationTypeForm;
    const formValues = {
      foundationType: controls.foundationType.value ?? undefined,
    };

    return formValues;
  }

  public getFormControls() {
    return this.foundationTypeForm.controls;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.foundationTypeForm.markAllAsTouched();
    if (this.foundationTypeForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }

  public get hasError() {
    const control = this.foundationTypeForm.get('foundationType');
    return !!control?.invalid && !!control?.touched;
  }
}
