import { LRUCache } from '../../utils/LRUCache';
var ImpressionObserver = /** @class */function () {
  function ImpressionObserver(size, hasher) {
    this.cache = new LRUCache(size);
    this.hasher = hasher;
  }
  ImpressionObserver.prototype.testAndSet = function (impression) {
    var hash = this.hasher(impression);
    var previous = this.cache.get(hash);
    this.cache.set(hash, impression.time);
    return previous;
  };
  return ImpressionObserver;
}();
export { ImpressionObserver };