/**
 * This service handles the communication with the legacy Api::V1::CustomerInput::ResponsesController.
 */
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppContext } from '../../../../app-config';
import { LegacyApiPagesCir } from '../quote-pages/models';

@Injectable({
  providedIn: 'root',
})
export class LegacyCustomerInputResponseService {
  @AppContext() private _appContext!: AppContext;
  private httpClient = inject(HttpClient);

  /**
   * Gets URL based on app context.
   */
  private get url() {
    return `${this._appContext.apiRoot.quoteV1}/customer_input_responses`;
  }

  /**
   * PATCH request to update discrete fields on the customer_input_response.
   */
  public updateCustomerInputResponse(payload: Partial<LegacyApiPagesCir>) {
    return this.httpClient.patch<LegacyApiPagesCir>(this.url, payload);
  }
}
