import { ERROR_CLIENT_DESTROYED, CLIENT_NOT_READY } from '../../logger/constants';
export function validateIfNotDestroyed(log, readinessManager, method) {
  if (!readinessManager.isDestroyed()) return true;
  log.error(ERROR_CLIENT_DESTROYED, [method]);
  return false;
}
export function validateIfOperational(log, readinessManager, method, featureFlagNameOrNames) {
  if (readinessManager.isReady() || readinessManager.isReadyFromCache()) return true;
  log.warn(CLIENT_NOT_READY, [method, featureFlagNameOrNames ? " for feature flag " + featureFlagNameOrNames.toString() : '']);
  return false;
}