<form [formGroup]="rentalNumberForm" (submit)="handleSubmit($event)" id="quote-form" class="qui-form-layout__col-center flex w-full flex-col gap-400">
  @if (allFieldsLocked()) {
    <app-locked-quote-alert></app-locked-quote-alert>
  }
  <app-form-card title="How many times do you plan to rent out your property in the next 12 months?" icon="lucideHouse">
    <select [appReadonlySelect]="isFieldLocked('rentalNumber')" formControlName="rentalNumber" data-testid="rental-number-select" class="select__control mt-10" [required]="true">
      <option disabled [value]="undefined">Planned number of times to rent:</option>
      <option [value]="0">0</option>
      @if (this.isFloridaOrLouisiana()) {
        <option [value]="5">1-5</option>
      } @else {
        <option [value]="2">1-2</option>
        <option [value]="5">3-5</option>
      }
      <option [value]="9">6-9</option>
      <option [value]="10">10+</option>
    </select>

    <div aria-live="polite" aria-atomic="true">
      @if (showFormRequiredError()) {
        <div class="form-help form-help--invalid flex items-center gap-100">
          <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
          <span class="error-message">Please select an annual rental frequency.</span>
        </div>
      }
    </div>
  </app-form-card>
</form>
