import { SubmittedFieldsState } from './lockable.store';

/**
 * Merges newly submitted form fields with existing submitted fields state.
 *
 * This function is crucial for form locking functionality:
 * 1. Maintains history of all submitted fields across multiple partial form submissions
 * 2. Prevents accidental unlocking of previously submitted fields
 * 3. Enables proper form state tracking for UI locks/validation
 *
 * @param currentSubmittedFields Current state of all submitted fields
 * @param formKey Identifier for the form being updated (e.g. 'about-you')
 * @param fields Array of field names that were just submitted
 * @returns Updated state with newly submitted fields merged with existing state
 */
export function mergeSubmittedFields(currentSubmittedFields: SubmittedFieldsState, formKey: string, fields: string[]): SubmittedFieldsState {
  const updatedFieldsMap = fields.reduce<Record<string, boolean>>(
    (map, field) => ({
      ...map,
      [field]: true,
    }),
    {},
  );

  return {
    ...currentSubmittedFields,
    [formKey]: {
      ...currentSubmittedFields[formKey],
      ...updatedFieldsMap,
    },
  };
}
