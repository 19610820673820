import { objectAssign } from '../utils/lang/objectAssign';
import { IN_SEGMENT } from '../utils/constants';
/**
 * This class provides a skeletal implementation of the ISplitsCacheSync interface
 * to minimize the effort required to implement this interface.
 */
var AbstractSplitsCacheSync = /** @class */function () {
  function AbstractSplitsCacheSync() {}
  AbstractSplitsCacheSync.prototype.addSplits = function (entries) {
    var _this = this;
    return entries.map(function (keyValuePair) {
      return _this.addSplit(keyValuePair[0], keyValuePair[1]);
    });
  };
  AbstractSplitsCacheSync.prototype.removeSplits = function (names) {
    var _this = this;
    return names.map(function (name) {
      return _this.removeSplit(name);
    });
  };
  AbstractSplitsCacheSync.prototype.getSplits = function (names) {
    var _this = this;
    var splits = {};
    names.forEach(function (name) {
      splits[name] = _this.getSplit(name);
    });
    return splits;
  };
  AbstractSplitsCacheSync.prototype.getAll = function () {
    var _this = this;
    return this.getSplitNames().map(function (key) {
      return _this.getSplit(key);
    });
  };
  /**
   * Check if the splits information is already stored in cache. This data can be preloaded.
   * It is used as condition to emit SDK_SPLITS_CACHE_LOADED, and then SDK_READY_FROM_CACHE.
   */
  AbstractSplitsCacheSync.prototype.checkCache = function () {
    return false;
  };
  /**
   * Kill `name` split and set `defaultTreatment` and `changeNumber`.
   * Used for SPLIT_KILL push notifications.
   *
   * @param {string} name
   * @param {string} defaultTreatment
   * @param {number} changeNumber
   * @returns {boolean} `true` if the operation successed updating the split, or `false` if no split is updated,
   * for instance, if the `changeNumber` is old, or if the split is not found (e.g., `/splitchanges` hasn't been fetched yet), or if the storage fails to apply the update.
   */
  AbstractSplitsCacheSync.prototype.killLocally = function (name, defaultTreatment, changeNumber) {
    var split = this.getSplit(name);
    if (split && (!split.changeNumber || split.changeNumber < changeNumber)) {
      var newSplit = objectAssign({}, split);
      newSplit.killed = true;
      newSplit.defaultTreatment = defaultTreatment;
      newSplit.changeNumber = changeNumber;
      return this.addSplit(name, newSplit);
    }
    return false;
  };
  return AbstractSplitsCacheSync;
}();
export { AbstractSplitsCacheSync };
/**
 * Given a parsed split, it returns a boolean flagging if its conditions use segments matchers (rules & whitelists).
 * This util is intended to simplify the implementation of `splitsCache::usesSegments` method
 */
export function usesSegments(split) {
  var conditions = split.conditions || [];
  for (var i = 0; i < conditions.length; i++) {
    var matchers = conditions[i].matcherGroup.matchers;
    for (var j = 0; j < matchers.length; j++) {
      if (matchers[j].matcherType === IN_SEGMENT) return true;
    }
  }
  return false;
}