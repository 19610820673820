import { ChangeDetectionStrategy, Component, inject, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideHouse, lucideCircleAlert } from '@ng-icons/lucide';
import { ReadonlyRadioDirective } from 'app/common/directives/readonly-radio.directive';
import { Lockable, WithLockableFields } from 'app/create-quote/form-locking';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { RoofShapeQuoteFormInput, RoofShapeValue } from './roof-shape.types';
// eslint-disable-next-line import/no-cycle
import { QuoteDraftState } from '../../../../global-store/quote-draft';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { FormCardComponent } from '../../shared/form-card.component';
import { LockedQuoteAlertComponent } from '../../../form-locking/locked-quote-alert.component';

@Component({
  selector: 'app-roof-shape',
  standalone: true,
  imports: [ReactiveFormsModule, FormCardComponent, NgIconComponent, ReadonlyRadioDirective, LockedQuoteAlertComponent],
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  templateUrl: './roof-shape.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: contents;
      }
      // #kinetic-overrides
      .radio-card__title {
        margin-block-start: 0;
      }
      .radio-card__header {
        gap: var(--space-100);
      }
      .radio-card {
        align-items: flex-start; // headers should all be aligned even if their content spans different line lengths
      }
    `,
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/roof-shape',
})
export class QuoteFormRoofShapeComponent extends BaseQuoteForm<RoofShapeQuoteFormInput> implements OnInit, Lockable {
  private optionsStore = inject(QuoteFormOptionsStore);
  public formDataIn: RoofShapeQuoteFormInput = this.quoteDraftStore.roofShapeQuoteFormSelector();

  public roofShapeOptions = this.optionsStore.roofShapeQuoteFormOptionsSelector();

  public roofShapeForm = new FormGroup({
    roofShape: new FormControl<RoofShapeValue | undefined>(undefined, Validators.required),
  });
  public ngOnInit() {
    if (this.formDataIn) {
      this.roofShapeForm.controls.roofShape.setValue(this.formDataIn.roofShape);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'roofShape'> {
    const { controls } = this.roofShapeForm;
    const formValues = {
      roofShape: controls.roofShape.value ?? undefined,
    };

    return formValues;
  }

  public getFormControls() {
    return this.roofShapeForm.controls;
  }

  public isLocked: Signal<{ [key: string]: boolean }> = WithLockableFields.createIsLocked.call(this);
  public isFieldLocked = WithLockableFields.isFieldLocked;
  public allFieldsLocked = WithLockableFields.allFieldsLocked;

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.roofShapeForm.markAllAsTouched();
    if (this.roofShapeForm.valid) {
      if (this.allFieldsLocked()) {
        super.navigateForward();
      } else {
        super.saveFormData(this.getFormValues());
      }
    }
  }

  get errorMessage(): string | null {
    const roofShapeControl = this.roofShapeForm.get('roofShape');

    if (!roofShapeControl) {
      return null;
    }

    const hasErrors = roofShapeControl.errors;
    const isTouchedOrDirty = roofShapeControl.touched || roofShapeControl.dirty;

    if (hasErrors && isTouchedOrDirty) {
      return 'This field is required';
    }

    return null;
  }
}
