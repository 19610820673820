import { DEDUPED, DROPPED, LOCALHOST_MODE, QUEUED } from '../../utils/constants';
import { findLatencyIndex } from '../findLatencyIndex';
var MAX_STREAMING_EVENTS = 20;
var MAX_TAGS = 10;
export var MAX_LATENCY_BUCKET_COUNT = 23;
export function newBuckets() {
  // MAX_LATENCY_BUCKET_COUNT (length) is 23
  // Not using Array.fill for old browsers compatibility
  return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
}
var ACCEPTANCE_RANGE = 0.001;
/**
 * Record telemetry if mode is not localhost.
 * All factory instances track telemetry on server-side, and 0.1% on client-side.
 */
export function shouldRecordTelemetry(_a) {
  var settings = _a.settings;
  return settings.mode !== LOCALHOST_MODE && (settings.core.key === undefined || Math.random() <= ACCEPTANCE_RANGE);
}
var TelemetryCacheInMemory = /** @class */function () {
  function TelemetryCacheInMemory(splits, segments) {
    this.splits = splits;
    this.segments = segments;
    // isEmpty flag
    this.e = true;
    this.notReadyUsage = 0;
    /** Usage stats */
    this.impressionStats = [0, 0, 0];
    this.eventStats = [0, 0];
    this.lastSync = {};
    this.httpErrors = {};
    this.httpLatencies = {};
    this.authRejections = 0;
    this.tokenRefreshes = 0;
    this.streamingEvents = [];
    this.tags = [];
    this.exceptions = {};
    this.latencies = {};
    this.updatesFromSSE = {
      sp: 0,
      ms: 0
    };
  }
  TelemetryCacheInMemory.prototype.isEmpty = function () {
    return this.e;
  };
  TelemetryCacheInMemory.prototype.clear = function () {};
  TelemetryCacheInMemory.prototype.pop = function () {
    this.e = true;
    return {
      lS: this.getLastSynchronization(),
      mL: this.popLatencies(),
      mE: this.popExceptions(),
      hE: this.popHttpErrors(),
      hL: this.popHttpLatencies(),
      tR: this.popTokenRefreshes(),
      aR: this.popAuthRejections(),
      iQ: this.getImpressionStats(QUEUED),
      iDe: this.getImpressionStats(DEDUPED),
      iDr: this.getImpressionStats(DROPPED),
      spC: this.splits && this.splits.getSplitNames().length,
      seC: this.segments && this.segments.getRegisteredSegments().length,
      skC: this.segments && this.segments.getKeysCount(),
      sL: this.getSessionLength(),
      eQ: this.getEventStats(QUEUED),
      eD: this.getEventStats(DROPPED),
      sE: this.popStreamingEvents(),
      t: this.popTags(),
      ufs: this.popUpdatesFromSSE()
    };
  };
  TelemetryCacheInMemory.prototype.getTimeUntilReady = function () {
    return this.timeUntilReady;
  };
  TelemetryCacheInMemory.prototype.recordTimeUntilReady = function (ms) {
    this.timeUntilReady = ms;
  };
  TelemetryCacheInMemory.prototype.getTimeUntilReadyFromCache = function () {
    return this.timeUntilReadyFromCache;
  };
  TelemetryCacheInMemory.prototype.recordTimeUntilReadyFromCache = function (ms) {
    this.timeUntilReadyFromCache = ms;
  };
  TelemetryCacheInMemory.prototype.getNonReadyUsage = function () {
    return this.notReadyUsage;
  };
  TelemetryCacheInMemory.prototype.recordNonReadyUsage = function () {
    this.notReadyUsage++;
  };
  TelemetryCacheInMemory.prototype.getImpressionStats = function (type) {
    return this.impressionStats[type];
  };
  TelemetryCacheInMemory.prototype.recordImpressionStats = function (type, count) {
    this.impressionStats[type] += count;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.getEventStats = function (type) {
    return this.eventStats[type];
  };
  TelemetryCacheInMemory.prototype.recordEventStats = function (type, count) {
    this.eventStats[type] += count;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.getLastSynchronization = function () {
    return this.lastSync;
  };
  TelemetryCacheInMemory.prototype.recordSuccessfulSync = function (resource, timeMs) {
    this.lastSync[resource] = timeMs;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.popHttpErrors = function () {
    var result = this.httpErrors;
    this.httpErrors = {};
    return result;
  };
  TelemetryCacheInMemory.prototype.recordHttpError = function (resource, status) {
    var statusErrors = this.httpErrors[resource] = this.httpErrors[resource] || {};
    statusErrors[status] = (statusErrors[status] || 0) + 1;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.popHttpLatencies = function () {
    var result = this.httpLatencies;
    this.httpLatencies = {};
    return result;
  };
  TelemetryCacheInMemory.prototype.recordHttpLatency = function (resource, latencyMs) {
    var latencyBuckets = this.httpLatencies[resource] = this.httpLatencies[resource] || newBuckets();
    latencyBuckets[findLatencyIndex(latencyMs)]++;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.popAuthRejections = function () {
    var result = this.authRejections;
    this.authRejections = 0;
    return result;
  };
  TelemetryCacheInMemory.prototype.recordAuthRejections = function () {
    this.authRejections++;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.popTokenRefreshes = function () {
    var result = this.tokenRefreshes;
    this.tokenRefreshes = 0;
    return result;
  };
  TelemetryCacheInMemory.prototype.recordTokenRefreshes = function () {
    this.tokenRefreshes++;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.popStreamingEvents = function () {
    return this.streamingEvents.splice(0);
  };
  TelemetryCacheInMemory.prototype.recordStreamingEvents = function (streamingEvent) {
    if (this.streamingEvents.length < MAX_STREAMING_EVENTS) {
      this.streamingEvents.push(streamingEvent);
    }
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.popTags = function () {
    return this.tags.splice(0);
  };
  TelemetryCacheInMemory.prototype.addTag = function (tag) {
    if (this.tags.length < MAX_TAGS) {
      this.tags.push(tag);
    }
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.getSessionLength = function () {
    return this.sessionLength;
  };
  TelemetryCacheInMemory.prototype.recordSessionLength = function (ms) {
    this.sessionLength = ms;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.popExceptions = function () {
    var result = this.exceptions;
    this.exceptions = {};
    return result;
  };
  TelemetryCacheInMemory.prototype.recordException = function (method) {
    this.exceptions[method] = (this.exceptions[method] || 0) + 1;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.popLatencies = function () {
    var result = this.latencies;
    this.latencies = {};
    return result;
  };
  TelemetryCacheInMemory.prototype.recordLatency = function (method, latencyMs) {
    var latencyBuckets = this.latencies[method] = this.latencies[method] || newBuckets();
    latencyBuckets[findLatencyIndex(latencyMs)]++;
    this.e = false;
  };
  TelemetryCacheInMemory.prototype.popUpdatesFromSSE = function () {
    var result = this.updatesFromSSE;
    this.updatesFromSSE = {
      sp: 0,
      ms: 0
    };
    return result;
  };
  TelemetryCacheInMemory.prototype.recordUpdatesFromSSE = function (type) {
    this.updatesFromSSE[type]++;
    this.e = false;
  };
  return TelemetryCacheInMemory;
}();
export { TelemetryCacheInMemory };