import { isObject, forOwn, merge } from '../../../utils/lang';
import { parseCondition } from './parseCondition';
function hasTreatmentChanged(prev, curr) {
  if (typeof prev !== typeof curr) return true;
  if (typeof prev === 'string') {
    // strings treatments, just compare
    return prev !== curr;
  } else {
    // has treatment and config, compare both
    return prev.treatment !== curr.treatment || prev.config !== curr.config;
  }
}
export function splitsParserFromSettingsFactory() {
  var previousMock = {
    'emptyMock': '1'
  };
  function mockUpdated(currentData) {
    var names = Object.keys(currentData);
    // Different amount of items
    if (names.length !== Object.keys(previousMock).length) {
      previousMock = merge({}, currentData);
      return true;
    }
    return names.some(function (name) {
      var newSplit = !previousMock[name];
      var newTreatment = hasTreatmentChanged(previousMock[name], currentData[name]);
      var changed = newSplit || newTreatment;
      if (changed) previousMock = merge({}, currentData);
      return changed;
    });
  }
  /**
   *
   * @param settings validated object with mocked features mapping.
   */
  return function splitsParserFromSettings(settings) {
    var features = settings.features || {};
    if (!mockUpdated(features)) return false;
    var splitObjects = {};
    forOwn(features, function (data, splitName) {
      var treatment = data;
      var config = null;
      if (isObject(data)) {
        treatment = data.treatment;
        config = data.config || config;
      }
      var configurations = {};
      if (config !== null) configurations[treatment] = config;
      splitObjects[splitName] = {
        trafficTypeName: 'localhost',
        conditions: [parseCondition({
          treatment: treatment
        })],
        configurations: configurations
      };
    });
    return splitObjects;
  };
}